import React, { useState, useEffect } from "react";
import {
  Alert,
  Button,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Upload,
  Row,
  Col,
} from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { get, post } from "../../../../helper/request";
import { InboxOutlined, LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { CheckCerrency } from "../../../../components/checkcurrency";
import { useSelector } from "react-redux";
import BoxScanQrCodeLoas from "../../../../components/boxpayment/BoxScanQrCodeLoas";
import { Document, Page, pdfjs } from "react-pdf";
import { renderApiDomain } from "../../../../helper/renderApiDomain";
const locale = require("react-redux-i18n").I18n;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const { Dragger } = Upload;
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");
      resolve(base64String);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};
const EasyFastPayForm = (props) => {
  const { paymentId } = props;
  const user = useSelector((state) => state.user);
  const profileCountry = useSelector((state) => state.user.profileCountry);
  const [form] = Form.useForm();
  const [bank, setBank] = useState(null);
  const [visible, setVisible] = useState(false);
  const [slip, setSlip] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [isFile, setIsFile] = useState(null);
  const [isFilePDF, setIsFilePDF] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  useEffect(() => {
    // if (!bank) {
    get(renderApiDomain() + "deposit/bank").then((res) => {
      setBank(res.data[0]);
    });
    // }
  }, []);
  const uploadOption = {
    onRemove: async (file) => {
      setIsFile(null);
      setIsFilePDF(null);
      await form.setFieldsValue({ upload: null });
      await form.validateFields(["upload"]);
    },

    beforeUpload: async (file) => {
      const isPNG = file.type === "image/png";
      const isJPG = file.type === "image/jpg";
      const isJPEG = file.type === "image/jpeg";
      const isPDF = file.type === "application/pdf";
      const isLt2M = file.size / 1024 / 1024 < 8;
      if (!isPNG && !isJPG && !isJPEG && !isPDF) {
        message.error(`${file.name} ${locale.t("acceptedfile")}`);
        setIsFile(null);
        await form.setFieldsValue({ upload: null });
      } else if (!isLt2M) {
        message.error(locale.t("acceptedfile"));
      } else {
        if (isPDF) {
          setIsFilePDF(file);
          setIsFile(file);
        } else if (!file.url && !file.preview) {
          file.preview = await fileToBase64(file);
          setIsFilePDF(null);
          setIsFile(`data:${file.type};base64,` + file.preview || file.url);
        } else {
          setIsFilePDF(null);
          setIsFile(`data:${file.type};base64,` + file.preview || file.url);
        }
      }

      // return false;
    },
  };

  const draggerOption = {
    name: "file_image",
    onRemove: (file) => {
      setSlip(null);
    },
    beforeUpload: (file) => {
      setSlip(file);
      setPreviewImage(file);
      console.log(file.url, "file");
      return false;
    },
    onChange(info) {
      console.log(info);
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    slip,
  };
  function onDocumentLoadSuccess({ numPages }) {
    setPageNumber(numPages);
  }
  function disabledDateTime() {
    // return {
    //   disabledHours: () => range(0, 24).splice(4, 20),
    //   disabledMinutes: () => range(30, 60),
    //   disabledSeconds: () => [55, 56],
    // };
  }

  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  const onFinish = async () => {
    try {
      const values = await form.validateFields();
      const formDataPayment = new FormData();

      formDataPayment.append("account_trade_id", props.account.id);
      formDataPayment.append("method", bank.bank);
      formDataPayment.append("amount_thb", props.amount);
      formDataPayment.append("amount", (props.amount / props.rate).toFixed(2));
      formDataPayment.append(
        "user_amount",
        (props.amount / props.rate).toFixed(2)
      );
      formDataPayment.append("date_payment", values.upload_date);
      formDataPayment.append("bank_account_number", values.account_number);
      formDataPayment.append(
        "transfer_slip",
        values.dragger.file.originFileObj
      );
      formDataPayment.append("payment_id", paymentId);

      setConfirmLoading(true);
      post(`${renderApiDomain()}user/account/deposit`, formDataPayment)
        .then((res) => {
          props.onSuccess({
            message: {
              title: locale.t("deposit_info.success.title"),
              description: locale.t("deposit_info.success.description"),
            },
          });
          setConfirmLoading(false);
          setVisible(false);
        })
        .catch((err) => {
          setConfirmLoading(false);
          console.log(err);
          message.error(err.response.data.message);
        });
    } catch (errorInfo) {
      message.error(errorInfo.response.data.error);
      setConfirmLoading(false);
      // message.error(errorInfo.message);
    }
  };
  const laos = localStorage.getItem("setLocale");
  const windowIner = window.innerWidth;
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  return (
    <>
      {bank ? (
        <>
          {step === 0 ? (
            <>
              <span className="fs-20 font-weight-bold ">
                3.{locale.t("deposit_info.deposit_process")}
              </span>
              <div className="text-gray my-3">
                {locale.t("deposit_info.recheck")}
              </div>
              <div>
                <BoxScanQrCodeLoas
                  isQrcode={`https://admin.iuxmarket.com/${bank.qr_code}`}
                  account={props.account}
                  rate={props.rate}
                  paymentSelected={props.paymentSelected}
                  amount={props.amount}
                  bankDetail={bank}
                />
              </div>
              <div style={{ display: step === 1 ? "none" : "block" }}>
                <>
                  <Divider />
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      // padding: "0rem 1rem",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      className="btn-outline-iux button-default-w  "
                      style={{ float: "left" }}
                      onClick={() => props.onBackStep()}
                    >
                      {locale.t("prev")}
                    </Button>
                    <Button
                      className="btn-primary-new-iux button-default-w "
                      onClick={() => {
                        setVisible(true);
                        setStep(1);
                      }}
                    >
                      {locale.t("next")}
                    </Button>
                  </div>
                </>
              </div>
            </>
          ) : (
            <div>
              <div className="fs-20 font-weight-bold mb-3">
                4.{locale.t("deposit_info.confirm_deposit")}
              </div>{" "}
              <Form
                form={form}
                name="modal_deposit"
                className="user"
                onFinish={onFinish}
              >
                <div className="container-withdraw-otp ">
                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={8}>
                      <div className="box-payment-detail ">
                        <div className="fs-14">{bank?.bank}</div>
                        <div className="mt-2">
                          <span className="text-lightgray-iux">
                            {" "}
                            {locale.t("deposit_info.currency")}: :
                          </span>{" "}
                          {profileCountry?.symbol}
                        </div>
                        <div className="mt-2">
                          <span className="text-lightgray-iux">
                            <b>{locale.t("deposit_info.mt_account")}:&nbsp;</b>
                          </span>
                          <b>{props.account.account_number}</b>
                        </div>
                        <div className="mt-2">
                          <span className="text-lightgray-iux">
                            {locale.t("deposit_info.payment.amount")}:
                          </span>{" "}
                          <b>
                            {props.amount} {profileCountry?.symbol}
                          </b>
                        </div>
                      </div>
                    </Col>
                    <Col xs={24} md={1}>
                      <Divider
                        type="vertical"
                        style={{ height: "100%" }}
                        className="d-none d-sm-block border-divider"
                      />
                      <Divider
                        style={{ margin: 0 }}
                        className="d-flex d-sm-none border-divider"
                      />
                    </Col>

                    <Col xs={24} md={14}>
                      <div className="box-payment-fund-detail ">
                        <div className="fs-14">
                          {locale.t("deposit_info.input_infomation")}
                        </div>
                        <div className="my-3 text-gray">
                          {locale.t("deposit_info.bank.number")}:{" "}
                        </div>
                        <Form.Item
                          name="account_number"
                          rules={[
                            {
                              required: true,
                              message: locale.t("deposit_error.input"),
                            },
                          ]}
                        >
                          <Input placeholder="1234567890" />
                        </Form.Item>{" "}
                        <div className="mb-3 text-gray">
                          {locale.t("deposit_info.payment.input_date")}:{" "}
                        </div>
                        <Form.Item
                          name="upload_date"
                          rules={[
                            {
                              type: "object",
                              required: true,
                              message: locale.t("deposit_error.input"),
                            },
                          ]}
                        >
                          <DatePicker
                            showTime={{ format: "HH:mm" }}
                            format="YYYY-MM-DD HH:mm"
                            disabledTime={disabledDateTime}
                            // showTime={{ value: moment("00:00:00", "HH:mm") }}
                            className="w-100"
                            changeOnBlur={true}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Divider
                    style={{ marginTop: 0 }}
                    className="border-divider"
                  />
                  <div className="text-center mb-2">
                    {locale.t("deposit_info.payment.input_file_description")}
                  </div>
                  <Form.Item
                    name="dragger"
                    rules={[
                      {
                        type: "object",
                        required: true,
                        message: locale.t("deposit_error.input"),
                      },
                    ]}
                  >
                    <Upload
                      listType="picture-card"
                      // onPreview={handlePreview}
                      showUploadList={false}
                      maxCount={1}
                      {...uploadOption}
                      //   fileList={file}
                      className="d-flex justify-content-center deposit-loas-upload"
                    >
                      {isFile ? (
                        <div
                          style={{
                            // maxWidth: "347px",

                            border: "1px dashed #1CB894",
                            borderRadius: 8,
                            position: "relative",
                          }}
                          className=" d-block"
                        >
                          <div
                            className="fs-20 cursor-pointer"
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 8,
                              zIndex: 8,
                            }}
                            onClick={() => {
                              form.setFieldsValue({ upload: null });
                              setIsFile(null);
                            }}
                          >
                            x
                          </div>
                          {isFilePDF ? (
                            <Document
                              file={isFile}
                              onLoadSuccess={onDocumentLoadSuccess}
                            >
                              <Page
                                width={200}
                                pageIndex={0}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                              />
                            </Document>
                          ) : (
                            <img
                              src={isFile}
                              alt="eieiei"
                              width={"100%"}
                              // height={"100%"}
                              style={{ borderRadius: 8 }}
                            />
                          )}
                        </div>
                      ) : (
                        <div
                          style={{ minHeight: 119 }}
                          className="text-gray d-flex align-items-center justify-content-center flex-column"
                        >
                          <img
                            src="/assets/images/icons/feather-upload.svg"
                            alt="upload"
                          />
                          <div className="text-gray">Upload Slip Here.</div>
                        </div>
                      )}
                    </Upload>

                    {/* <Dragger
                      {...draggerOption}
                      onPreview={handlePreview}
                      className="user deposit-loas-upload mx-auto"
                    >
                      <div
                        className="text-gray d-flex align-items-center justify-content-center flex-column"
                        style={{ height: "119px", borderRadius: 8 }}
                      >
                        <img
                          src="/assets/images/icons/feather-upload.svg"
                          alt="upload"
                        />

                        <div className="text-gray">Upload Slip Here.</div>
                        <img
                          alt="example"
                          style={{
                            width: "100%",
                          }}
                          src={previewImage}
                        />
                      </div>
                    </Dragger> */}
                  </Form.Item>
                </div>

                <div className="mt-3">
                  <div className="d-flex justify-content-between">
                    <Button
                      onClick={() => setStep(0)}
                      className="btn-outline-iux button-default-w "
                    >
                      {locale.t("prev")}
                    </Button>

                    <Button
                      loading={confirmLoading}
                      className="btn-primary-new-iux button-default-w"
                      // onClick={onFinish}
                      htmlType="submit"
                    >
                      {locale.t("register_button_text")}
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          )}
        </>
      ) : (
        <LoadingOutlined style={{ fontSize: 24 }} spin />
      )}
    </>
  );
};

export default EasyFastPayForm;
