import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Icon,
  Input,
  message,
  Modal,
  Row,
  Select,
  Statistic,
  Upload,
} from "antd";
import { CloseOutlined, GlobalOutlined } from "@ant-design/icons";
import { get, post } from "../../helper/request";
import { connect, useDispatch, useSelector } from "react-redux";
import { getUser } from "../../actions/user";

import moment from "moment";

import { Document, Page, pdfjs } from "react-pdf";
import { Checkbox } from "antd";
import { getBankList } from "../../api/verifyuser";
import { onOpenPolicy } from "../../helper/policy";
import { postChangePhoneOtp, postCheckPassword } from "../../api/account";
import OtpInput from "../../components/otpinput/OtpInput";
import FormBookbank from "../dashboard-content/fund/verifies/FormBookbank";
import { renderApiDomain } from "../../helper/renderApiDomain";

const { Option } = Select;
const { Countdown } = Statistic;
const styleInput = {
  // height: 42,
  borderRadius: 8,
};

const OtpButton = styled(Button)`
  background-color: ${(props) =>
    props.countDown !== 0 ? "#d0d0d02e " : "#fff"} !important;
  border: ${(props) =>
    props.countDown !== 0
      ? "1px solid #d0d0d02e "
      : "1px solid #1CB894"} !important;
  color: ${(props) => (props.countDown !== 0 ? "gray" : "#1CB894")} !important;
  border-radius: 4px;
  height: 40px;
`;

const PhoneDialog = ({
  isModalVisible,
  handleCancel,
  profile,
  locale,
  prefixPhone,
  numberPhone,
  verifyFrom,
  title,
  deatil,
}) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [countDown, setCountDown] = useState(0);
  const [location, setLocation] = useState(0);
  const [countries, setCountries] = useState(null);
  const [phone, setPhone] = useState(undefined);
  const [isTime, setIsTime] = useState(moment().add(180, "s").toDate());
  const [otp, setOtp] = useState(undefined);
  const [data, setData] = useState(null);
  const [isFile, setIsFile] = useState(null);
  const [isFileName, setIsFileName] = useState(null);
  const [isFilePDF, setIsFilePDF] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [isStep, setIsStep] = useState(1);
  const [afterClose, setAfterClose] = useState(false);
  const [loading, setLoading] = useState({
    phone: false,
    otp: false,
    button: false,
  });
  const [form] = Form.useForm();
  const [file, setFile] = useState(null);
  const [checked, setChecked] = useState(false);
  const [dataBankList, setDataBankList] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const onChange = (value) => {
    setOtp(value);

    if (value.trim().length === 6) {
      confirmOtp(value);
    } else {
      setIsError(false);
    }
  };
  useEffect(() => {
    fetchBankList();
  }, []);

  const overviewMemo = useMemo(
    () => (
      <Countdown
        // title="Countdown"
        format="ss"
        value={isTime}
        onFinish={() => setIsTime(null)}
      />
    ),
    [isTime]
  );

  const onSelectLocation = (event) => {
    setLocation(event.target.value);
  };

  const onChangePolicy = (e) => {
    setChecked(e.target.checked);
  };
  const requestOtp = async (values) => {
    setLoadingButton(true);
    try {
      const ph = `${prefixPhone}${
        values.phonenumber.charAt(0) === "0"
          ? values.phonenumber.substring(1)
          : values.phonenumber
      }`;
      const payload = {
        phone_number: ph,
      };

      const res = await postChangePhoneOtp(payload);
      setLoadingButton(false);
      setPhone(ph);
      setData(res);
      setIsTime(moment().add(180, "s").toDate());
      setIsStep(3);
      setLoading({ phone: false });
      setIsError(false);
      message.success(locale.t("modal_otp.validate.success"));
    } catch (error) {
      if (error?.response?.data?.code === 1008) {
        message.error("Duplicate phone number");
      } else {
        message.error(error?.response?.data?.error);
      }

      setLoadingButton(false);
      console.log(error);
      setIsTime(null);
    }
  };
  const confirmOtp = async (value) => {
    const ph = `${prefixPhone}${phone}`;
    try {
      setLoading({ otp: true });
      await post(renderApiDomain() + "phone/confirm", {
        code: +value,
      });
      // setTimeout(async () => {
      //   await post(renderApiDomain() + "switch-otp", {
      //     to: "phone",
      //   });
      // }, 3000);

      setLoading({ otp: false });
      setIsStep(4);
      dispatch(getUser());
      message.success(locale.t("modal_otp.confirm.success"));
    } catch (e) {
      setIsError(true);
      setOtp("");
      message.error(locale.t("modal_otp.confirm.error"));
      setLoading({ otp: false });
      console.log(e);
      setCountDown(0);
    }
  };
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");
        resolve(base64String);
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleVerifyBookBank = async () => {
    setisLoading(true);
    try {
      const values = await form.validateFields();

      const formData = new FormData();
      const filebookbank = values?.upload?.file.preview
        ? values?.upload?.file.preview
        : await fileToBase64(values?.upload?.file.originFileObj)
            .then((base64String) => {
              return base64String;
            })
            .catch((error) => {
              console.error(error);
            });

      const payload = {
        book_bank: filebookbank,
        bank_branch: values?.bank_branch,
        bank_account_type: values?.bank_account_type,
        bank_account_number: values.bank_number,
        bank_id: values.bank_name,
        bank_account_name: values.bookbank_name,
      };

      post(renderApiDomain() + "user/verify-account", payload)
        .then((res) => {
          setLoading({ button: false });
          setisLoading(false);
          handleCancel();
          dispatch(getUser());
          // message.success(locale.t("modal_otp.confirm.success"));
        })
        .catch((e) => {
          setisLoading(false);
          message.error(e.response.data.message);
          setLoading({ button: false });
        });
    } catch (e) {
      setLoading({ button: false });
    }
  };
  const fetchBankList = async () => {
    try {
      const { data } = await getBankList();
      setDataBankList(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const onCheckPassword = async (values) => {
    setLoadingButton(true);
    try {
      const payload = {
        password: values.currentPassword,
      };
      const { data } = await postCheckPassword(payload);
      // message.success("success");
      setLoadingButton(false);
      setIsStep(2);
    } catch (error) {
      message.error("Invalid password!");
      setLoadingButton(false);
      console.log(error);
    }
  };
  return (
    <div className="user">
      <Modal
        // title={null}
        visible={isModalVisible.phone}
        footer={null}
        closable={true}
        onCancel={() => {
          form.resetFields();
          setChecked(false);
          setIsStep(1);
          setOtp(undefined);
          handleCancel();
        }}
        title={false}
        className="w-modal width-modal-openAccount user user-change"
      >
        <div style={{ minHeight: 500, position: "relative" }}>
          <div className="ps-4 pe-4 user">
            {isStep === 1 && (
              <>
                <div className="fs-20 fw-700 text-main-blakc text-center">
                  {title || locale.t("verify_that_title")}
                </div>
                <div className="text-primary-sub-iux text-center mt-4">
                  {profile.email}
                </div>
                <div className="text-center fs-12 text-lightgray-iux mb-5">
                  {locale.t("verify_sub_title")}
                </div>
                <Form
                  onFinish={onCheckPassword}
                  className="p-2 p-md-4 mt-5 "
                  form={form}
                  layout="vertical"
                >
                  <div
                    style={{ height: 300 }}
                    className="d-flex align-items-center w-100"
                  >
                    <Form.Item
                      name="currentPassword"
                      label={locale.t("current_password")}
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                      ]}
                      style={{ color: "#8D8D8D" }}
                      className="w-100"
                    >
                      <Input.Password
                        placeholder={locale.t("current_password")}
                        className="user-password"
                      />
                    </Form.Item>
                  </div>
                  <Form.Item shouldUpdate>
                    {({
                      getFieldsValue,
                      getFieldValue,
                      isFieldTouched,
                      getFieldsError,
                    }) => (
                      <div className="d-flex align-items-end">
                        <Button
                          htmlType="submit"
                          shape="round"
                          loading={loadingButton}
                          disabled={
                            !isFieldTouched("currentPassword") ||
                            getFieldsError().filter(
                              ({ errors }) => errors.length
                            ).length
                          }
                          className="mt-5 w-100 btn-primary-new-iux"
                          type="primary"
                          block
                        >
                          {locale.t("dashboardWallet.modal.confirm")}
                        </Button>
                      </div>
                    )}
                  </Form.Item>
                </Form>
              </>
            )}
            {isStep === 2 && (
              <>
                <div className="fs-20 fw-700 text-main-blakc text-center mt-4">
                  {title || locale.t("change_phone_title")}
                </div>
                <div className="text-center">{deatil || ""}</div>
                <Form
                  onFinish={requestOtp}
                  className="p-2 p-md-4 mt-5"
                  initialValues={{
                    phonenumber: "",
                  }}
                  form={form}
                  layout="vertical"
                >
                  <div
                    style={{ minHeight: 300 }}
                    className="d-flex align-items-center w-100"
                  >
                    <div className="w-100">
                      <div className="mt-4 user">
                        {locale.t("modal_otp.label_input")}
                      </div>
                      <Form.Item
                        name="phonenumber"
                        rules={[
                          {
                            required: true,
                            message: locale.t("..."),
                          },
                        ]}
                      >
                        <Input
                          addonBefore={<div>{prefixPhone}</div>}
                          placeholder="xxxxxxxxx"
                          style={{ width: "100%" }}
                          maxLength={13}
                          defaultValue={phone}
                          // onChange={(e) => setPhone(e.target.value)}
                          autocomplete="off"
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div
                    className="text-center mt-2 mb-2 d-flex align-items-center flex-column justify-content-end w-100"
                    // style={{ minheight: 300 }}
                  >
                    <div className="mt-3 text-center ">
                      <Checkbox onChange={onChangePolicy}>
                        <span>{locale.t("accept_to_condition")} </span>
                        <span
                          onClick={() => onOpenPolicy("sms_iux")}
                          className="text-primary-new-iux cursor-pointer align-items-center"
                        >
                          {locale.t("contact_sidebar.lable_4")}
                        </span>
                      </Checkbox>
                      <div className="mt-3 text-center fs-10 text-lightgray">
                        {locale.t("sub_change_condition")}
                      </div>
                    </div>

                    {/* <OtpButton
                      loading={loading.phone}
                      countDown={countDown}
                      disabled={countDown !== 0 || !phone}
                      onClick={() => requestOtp("otp")}
                    >
                      {locale.t("modal_otp.button_otp")}
                    </OtpButton> */}
                  </div>{" "}
                  <Form.Item shouldUpdate>
                    {({
                      getFieldsValue,
                      getFieldValue,
                      isFieldTouched,
                      getFieldsError,
                    }) => (
                      <div className="d-flex w-100">
                        <Button
                          htmlType="submit"
                          shape="round"
                          loading={loadingButton}
                          disabled={
                            !checked ||
                            !isFieldTouched("phonenumber") ||
                            getFieldsError().filter(
                              ({ errors }) => errors.length
                            ).length
                          }
                          className="mt-5 w-100 btn-primary-new-iux"
                          type="primary"
                          block
                        >
                          {locale.t("modal_otp.button_otp")}
                        </Button>
                      </div>
                    )}
                  </Form.Item>
                  {/* {countDown !== 0 && (
                    <p className="text-center">{`${locale.t(
                      "modal_otp.request_again"
                    )} ${countDown}`}</p>
                  )} */}
                </Form>
              </>
            )}
            {isStep === 3 && (
              <>
                <div className="fs-20 fw-700 text-main-blakc text-center mt-4 mb-5">
                  {title || locale.t("change_phone_title")}
                </div>
                <div className="text-main-black fs-14 text-center mt-5">
                  {locale.t("verify_new_phone")}
                </div>
                <div className="text-main-black fs-12 mt-3 text-center">
                  {locale.t("enterotp")}
                </div>
                <div className="text-main-black fs-14 text-center mt-3">
                  {phone}
                </div>
                <div className="mt-2 user d-flex justify-content-center">
                  {/* <Form.Item
                    name="otp"
                    rules={[
                      {
                        required: true,
                        message: locale.t("..."),
                      },
                    ]}
                  >
                    <Input
                      type="number"
                      placeholder="Enter your OTP Psassword"
                      style={styleInput}
                      disabled={data === null}
                      onChange={(e) => setOtp(e.target.value)}
                      className="user-change"
                      onKeyPress={(event) => {
                        if (!/[0-9.]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item> */}
                  <OtpInput
                    value={otp}
                    valueLength={6}
                    onChange={onChange}
                    isError={isError}
                    isSuccess={isSuccess}
                  />
                </div>{" "}
                <div className="text-center mt-4">
                  <div className="fs-14 mt-3 text-center text-gray d-flex align-items-center justify-content-center flex-column">
                    {isError && (
                      <div className="fs-16 text-is-error d-flex align-items-center">
                        {locale.t("codenotmatch")}&nbsp;
                        {/* {isTime ? (
                          <div className="d-flex align-items-center">
                            &nbsp;{overviewMemo} &nbsp;{locale.t("seconds")}
                          </div>
                        ) : (
                          <span
                            className="text-primary-new-iux font-weight-bold cursor-pointer"
                            onClick={() =>
                              requestOtp({
                                phonenumber: phone?.replace(prefixPhone, ""),
                              })
                            }
                          >
                            {locale.t("requestotp")}
                          </span>
                        )} */}
                      </div>
                    )}

                    <div
                      className={` align-items-center ${
                        isTime ? "d-flex" : "d-none"
                      }`}
                    >
                      {locale.t("codeexpiresin")}&nbsp;{overviewMemo} &nbsp;
                      {locale.t("seconds")}
                    </div>

                    {!isTime && (
                      <div className="fs-16">
                        {locale.t("didotp")}
                        <span
                          className="text-primary-new-iux font-weight-bold cursor-pointer"
                          onClick={() =>
                            requestOtp({
                              phonenumber: phone?.replace(prefixPhone, ""),
                            })
                          }
                        >
                          {locale.t("requestotp")}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            {isStep === 4 && (
              <>
                <div className="fs-20 fw-700 text-main-blakc text-center mt-4">
                  {locale.t("step3")}
                </div>
                <div
                  className="d-flex justify-content-center my-5 flex-column align-items-center"
                  style={{ minHeight: 500 }}
                >
                  <div className="iux-scan success d-flex justify-content-center mt-4">
                    <img
                      src="/assets/images/verify/success.svg"
                      alt="success"
                    />
                  </div>
                  <div className="fs-16 mt-4 text-lightgray">
                    {locale.t("verify_phone_success")}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
      <div id={isModalVisible.bank ? "openModal" : "closeModal"} />
      {/* <Modal
        closable={true}
        title={false}
        open={isModalVisible.bank}
        footer={null}
        className="w-modal width-modal-openAccount user user-change"
        onCancel={() => handleCancel()}
      >
        <div className=" p-2 p-md-5">
          <div className="text-center f-20">{locale.t("modal_bank.title")}</div>
          <div className="box-info-bookbank d-flex my-4">
            <div className="mr-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.33138 9.33333C7.33138 9.70153 7.62985 10 7.99805 10C8.36625 10 8.66471 9.70153 8.66471 9.33333V4.66667C8.66471 4.29848 8.36625 4 7.99805 4C7.62985 4 7.33138 4.29848 7.33138 4.66667V9.33333ZM7.99805 10.6667C7.62985 10.6667 7.33138 10.9651 7.33138 11.3333C7.33138 11.7015 7.62985 12 7.99805 12C8.36625 12 8.66471 11.7015 8.66471 11.3333C8.66471 10.9651 8.36625 10.6667 7.99805 10.6667Z"
                  fill="#999999"
                />
              </svg>
            </div>
            <p className="">{locale.t("modal_bank.description")}</p>
          </div> */}
      {/* {verifyBookBankForm()} */}
      <div>
        <FormBookbank
          onSuccess={() => handleCancel()}
          onCancel={() => handleCancel()}
          isUpdateBank={true}
          isOpen={isModalVisible.bank}
        />
      </div>
      {/* </div>
      </Modal> */}
    </div>
  );
};

export default PhoneDialog;
