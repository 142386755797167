import {
  UPDATE_LOADING,
  UPDATE_LOADING_ARCHIVE,
  SET_PROFILE,
  UPDATE_VERIFIER_USER,
  INITIALIZE_CHANGED,
  UPDATE_VERIFIER_BOOKBANK,
  SET_ARCHIVE,
  UPDATE_LOADING_BANK,
  SET_BANK,
  SET_IPADDRESS,
  SET_ACCOUNT,
  SET_USER_VERIFY,
  UPDTAE_ACCOUNT,
  SET_USER_VERIFY_BOOKBANK,
  SET_LEVERAGE,
  SET_ACCOUNT_TYPE_LIST,
  LOADING_ACCOUNT_TYPE_LIST,
  SET_ACCOUNT_TYPE_LIST_DEMO,
  SET_COUNTRIES,
  UPDATE_LOADING_COUNTRIES,
  SET_BONUS_CONFIG,
  SET_PROFILE_COUNTRIES,
  INITIALIZE_LOADING,
} from "../types";
import { get, post } from "../helper/request";
import { LogoutAuth } from "./auth";
import Axios from "axios";
import { renderApiDomain } from "../helper/renderApiDomain";

export const getUser = () => {
  return async (dispatch) => {
    dispatch({
      type: INITIALIZE_LOADING,
      payload: true,
    });
    await dispatch(getUserv2());
    dispatch(getIpAddress());
    await dispatch(getAccount());
    // dispatch(getBank());
    // dispatch(getCountries());
    await dispatch(getUserVerifyv2());
    dispatch(getUserBookbankv2());
    dispatch(getLeverages());
    dispatch(getBonusConfig());
    dispatch(getAccountTypeListDeme());
    dispatch(getAccountTypeList());
    dispatch({
      type: INITIALIZE_LOADING,
      payload: false,
    });
  };
};
export const getUserLogin = () => {
  return (dispatch) => {
    // dispatch(getUserv2());
    dispatch(getIpAddress());
    dispatch(getAccount());
    // dispatch(getBank());
    dispatch(getCountries());
    dispatch(getUserVerifyv2());
    dispatch(getUserBookbankv2());
    dispatch(getLeverages());
    dispatch(getBonusConfig());
    dispatch(getAccountTypeListDeme());
    dispatch(getAccountTypeList());
  };
};
export const getUserv2 = () => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LOADING,
      payload: true,
    });
    return get(`${renderApiDomain()}me`)
      .then(async ({ data }) => {
        dispatch({
          type: SET_PROFILE,
          payload: data.data,
        });
        localStorage.setItem("isAuth", "true");
        dispatch(getCountries(data?.data?.personal_detail?.country_id));
        dispatch(getBank(data?.data?.personal_detail?.country_id));
        window.seon.config({
          host: "seondf.com",
          session_id: data?.data?.session_id,
          audio_fingerprint: true,
          canvas_fingerprint: true,
          webgl_fingerprint: true,
          onSuccess: function (message) {
            console.log("success", message);
          },
          onError: function (message) {
            console.log("error", message);
          },
        });
        let sessionId = await window.seon.getBase64Session();

        dispatch({
          type: INITIALIZE_CHANGED,
          payload: true,
        });

        return { success: true, data: data };
      })
      .catch((error) => {
        console.log(error, "response");
        dispatch({
          type: UPDATE_LOADING,
          payload: false,
        });
        dispatch({
          type: INITIALIZE_CHANGED,
          payload: true,
        });

        // window.location.assign("/");
        // dispatch(LogoutAuth());
        return { success: false, data: error.response };
      });
  };
};
export const getUserVerifyv2 = () => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LOADING,
      payload: true,
    });
    return get(`${renderApiDomain()}user/verify-user`)
      .then(({ data }) => {
        dispatch({
          type: SET_USER_VERIFY,
          payload: data.data,
        });
        dispatch({
          type: INITIALIZE_CHANGED,
          payload: true,
        });
        dispatch({
          type: UPDATE_LOADING,
          payload: false,
        });
        return { success: true, data: data };
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_LOADING,
          payload: false,
        });
        dispatch({
          type: SET_USER_VERIFY,
          payload: {
            status: "not_verify",
            userVerifyInfo: null,
          },
        });
        dispatch({
          type: INITIALIZE_CHANGED,
          payload: true,
        });
        // dispatch(LogoutAuth());
        return { success: false, data: error.response };
      });
  };
};
export const getUserBookbankv2 = () => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LOADING,
      payload: true,
    });
    return get(`${renderApiDomain()}user/verify-account`)
      .then(({ data }) => {
        dispatch({
          type: SET_USER_VERIFY_BOOKBANK,
          payload: data.data,
        });
        dispatch({
          type: INITIALIZE_CHANGED,
          payload: true,
        });
        dispatch({
          type: UPDATE_LOADING,
          payload: false,
        });
        return { success: true, data: data };
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_LOADING,
          payload: false,
        });
        dispatch({
          type: INITIALIZE_CHANGED,
          payload: true,
        });
        dispatch(
          updateVerifierBookBank({
            status: "not_verify",
            id: null,
            user_id: null,
            bank_name: null,
            bank_account_name: null,
            bank_account_number: null,
            book_bank: null,
            country_id: null,
            reason: null,
            current: null,
            flags: null,
            actionAt: null,
            action_seconds: null,
            createdAt: null,
            updatedAt: null,
            deletedAt: null,
          })
        );
        // dispatch(LogoutAuth());
        return { success: false, data: error.response };
      });
  };
};

export const getAccount = () => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LOADING,
      payload: true,
    });
    return get(`${renderApiDomain()}user/account`)
      .then(({ data }) => {
        data.data.length > 0 &&
          data.data.map((e, i) => {
            if (e.account_type === "StandardBonus") {
              data.data[i].account_name_show = "Standard+";
            } else {
              data.data[i].account_name_show = e.account_type;
            }
          });
        // localStorage.setItem("isAuth", "true");
        dispatch({
          type: SET_ACCOUNT,
          payload: data.data,
        });
        dispatch({
          type: INITIALIZE_CHANGED,
          payload: true,
        });

        dispatch({
          type: UPDATE_LOADING,
          payload: false,
        });
        return { success: true, data: data };
      })
      .catch(({ response }) => {
        dispatch({
          type: UPDATE_LOADING,
          payload: false,
        });
        dispatch({
          type: INITIALIZE_CHANGED,
          payload: true,
        });
        // dispatch(LogoutAuth());
        return { success: false, data: response };
      });
  };
};
export const getBonusConfig = () => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LOADING,
      payload: true,
    });
    return get(`${renderApiDomain()}bonus/config`)
      .then(({ data }) => {
        dispatch({
          type: SET_BONUS_CONFIG,
          payload: data?.data,
        });
        dispatch({
          type: INITIALIZE_CHANGED,
          payload: true,
        });

        dispatch({
          type: UPDATE_LOADING,
          payload: false,
        });
        return { success: true, data: data };
      })
      .catch(({ response }) => {
        dispatch({
          type: UPDATE_LOADING,
          payload: false,
        });
        dispatch({
          type: INITIALIZE_CHANGED,
          payload: true,
        });
        // dispatch(LogoutAuth());
        return { success: false, data: response };
      });
  };
};

export const getArchive = () => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LOADING_ARCHIVE,
      payload: true,
    });
    return get(`${renderApiDomain() + `user/account/archive`}`)
      .then(({ data }) => {
        dispatch({
          type: SET_ARCHIVE,
          payload: data.data,
        });
        dispatch({
          type: INITIALIZE_CHANGED,
          payload: true,
        });
        return { success: true, data: data };
      })
      .catch(({ response }) => {
        dispatch({
          type: UPDATE_LOADING_ARCHIVE,
          payload: false,
        });
        dispatch({
          type: INITIALIZE_CHANGED,
          payload: true,
        });
        return { success: false, data: response };
      });
  };
};

export const getBank = (country_id) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LOADING_BANK,
      payload: true,
    });
    return get(`${renderApiDomain() + `payment`}`)
      .then(({ data }) => {
        if (country_id === 6) {
          let dataIndex = data.findIndex((e) => e.id === 21);
          let dataFastAsia = data.findIndex((e) => e.id === 19);
          let duitnow = data;
          if (dataIndex >= 0) {
            duitnow[dataIndex].logo = "echelon_my.png";
            duitnow[dataIndex].name = "DuitNow QR";
            duitnow[dataIndex].method = "manual";
            duitnow[dataIndex].recommend = 1;
          }
          if (dataFastAsia >= 0) {
            duitnow[dataFastAsia].recommend = 0;
          }

          dispatch({
            type: SET_BANK,
            payload: duitnow,
          });
        } else {
          dispatch({
            type: SET_BANK,
            payload: data,
          });
        }

        dispatch({
          type: INITIALIZE_CHANGED,
          payload: true,
        });
        dispatch({
          type: UPDATE_LOADING_BANK,
          payload: false,
        });
        return { success: true, data: data };
      })
      .catch(({ response }) => {
        console.log(response);
        dispatch({
          type: UPDATE_LOADING_BANK,
          payload: false,
        });
        dispatch({
          type: INITIALIZE_CHANGED,
          payload: true,
        });
        return { success: false, data: response };
      });
  };
};
export const getCountries = (id) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LOADING_COUNTRIES,
      payload: true,
    });
    return get(`${renderApiDomain() + `countries`}`)
      .then(({ data }) => {
        if (id) {
          dispatch({
            type: SET_PROFILE_COUNTRIES,
            payload: data.data.find((e) => e.id === id),
          });
        }
        dispatch({
          type: SET_COUNTRIES,
          payload: data.data,
        });
        dispatch({
          type: INITIALIZE_CHANGED,
          payload: true,
        });
        dispatch({
          type: UPDATE_LOADING_COUNTRIES,
          payload: false,
        });
        return { success: true, data: data.data };
      })
      .catch(({ response }) => {
        dispatch({
          type: UPDATE_LOADING_COUNTRIES,
          payload: false,
        });
        dispatch({
          type: INITIALIZE_CHANGED,
          payload: true,
        });
        return { success: false, data: response };
      });
  };
};
export const getLeverages = () => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LOADING_BANK,
      payload: true,
    });
    return get(`${renderApiDomain() + `user/account/leverage`}`)
      .then(({ data }) => {
        let dataDetail = [];
        let checkoj = Object.keys(data);
        let test = checkoj.map(
          (elem, index) =>
            (data[elem] = data[elem].map((e, i) => {
              if (e >= 1000) {
                return { title: "High risk", values: e };
              } else if (e < 100) {
                return { title: "Low risk", values: e };
              } else {
                return { title: "Medium risk", values: e };
              }
            }))
        );
        // dataDetail = data.map((e, i) => {

        // });

        // const dataECN = data.ECN.map((e, i) => {
        //   return { name: e, values: e };
        // });
        // console.log(dataECN, "dataECN");
        dispatch({
          type: SET_LEVERAGE,
          payload: data,
        });
        dispatch({
          type: INITIALIZE_CHANGED,
          payload: true,
        });
        dispatch({
          type: UPDATE_LOADING_BANK,
          payload: false,
        });
        return { success: true, data: data.data };
      })
      .catch(({ response }) => {
        dispatch({
          type: UPDATE_LOADING_BANK,
          payload: false,
        });
        dispatch({
          type: INITIALIZE_CHANGED,
          payload: true,
        });
        return { success: false, data: response };
      });
  };
};
export const getAccountTypeList = () => {
  return (dispatch) => {
    dispatch({
      type: LOADING_ACCOUNT_TYPE_LIST,
      payload: true,
    });
    return get(`${renderApiDomain() + `account/types/mt5`}`)
      .then(({ data }) => {
        // dataDetail = data.map((e, i) => {

        // });

        // const dataECN = data.ECN.map((e, i) => {
        //   return { name: e, values: e };
        // });
        // console.log(dataECN, "dataECN");
        dispatch({
          type: SET_ACCOUNT_TYPE_LIST,
          payload: data.data,
        });
        // dispatch({
        //   type: INITIALIZE_CHANGED,
        //   payload: true,
        // });
        // dispatch({
        //   type: LOADING_ACCOUNT_TYPE_LIST,
        //   payload: false,
        // });
        return { success: true, data: data.data };
      })
      .catch(({ response }) => {
        // dispatch({
        //   type: UPDATE_LOADING_BANK,
        //   payload: false,
        // });
        // dispatch({
        //   type: INITIALIZE_CHANGED,
        //   payload: true,
        // });
        return { success: false, data: response };
      });
  };
};
export const getAccountTypeListDeme = () => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LOADING_BANK,
      payload: true,
    });
    return get(`${renderApiDomain() + `account/types/mt5/demo`}`)
      .then(({ data }) => {
        dispatch({
          type: SET_ACCOUNT_TYPE_LIST_DEMO,
          payload: data.data,
        });
        // dataDetail = data.map((e, i) => {

        // });

        // const dataECN = data.ECN.map((e, i) => {
        //   return { name: e, values: e };
        // });
        // console.log(dataECN, "dataECN");
        // dispatch({
        //   type: SET_LEVERAGE,
        //   payload: data,
        // });
        // dispatch({
        //   type: INITIALIZE_CHANGED,
        //   payload: true,
        // });
        // dispatch({
        //   type: UPDATE_LOADING_BANK,
        //   payload: false,
        // });
        return { success: true, data: data.data };
      })
      .catch(({ response }) => {
        // dispatch({
        //   type: UPDATE_LOADING_BANK,
        //   payload: false,
        // });
        // dispatch({
        //   type: INITIALIZE_CHANGED,
        //   payload: true,
        // });
        return { success: false, data: response };
      });
  };
};

export const updateVerifierUser = (verify) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_VERIFIER_USER,
      payload: verify,
    });
  };
};
export const updateNotification = (verify) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_VERIFIER_USER,
      payload: verify,
    });
  };
};

export const updateVerifierBookBank = (verify) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_VERIFIER_BOOKBANK,
      payload: verify,
    });
  };
};

export const getIpAddress = () => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LOADING,
      payload: true,
    });
    return Axios.post(`https://ipv4.icanhazip.com`)
      .then(({ data }) => {
        dispatch({
          type: SET_IPADDRESS,
          payload: data,
        });
        dispatch({
          type: INITIALIZE_CHANGED,
          payload: true,
        });
        return { success: true, data: data };
      })
      .catch(({ response }) => {
        dispatch({
          type: UPDATE_LOADING,
          payload: false,
        });
        dispatch({
          type: INITIALIZE_CHANGED,
          payload: true,
        });
        return { success: false, data: response };
      });
    // return (dispatch) => {
    //   dispatch({
    //     type: SET_IPADDRESS,
    //     payload: ip,
    //   });
    // };
  };
};

export const YongAuth = (user) => {
  return (dispatch) => {
    if (!user.isAuth) {
      dispatch({
        type: UPDATE_LOADING,
        payload: false,
      });
      dispatch({
        type: INITIALIZE_CHANGED,
        payload: true,
      });
      // return dispatch(LogoutAuth());
    } else {
      const onPatch = () => {
        dispatch(getUser());
        // dispatch(getIpAddress())
        dispatch(getBank());
      };
      return onPatch();
    }
  };
};
export const updateBalance = (account) => {
  return (dispatch) => {
    dispatch({
      type: UPDTAE_ACCOUNT,
      payload: account,
    });
  };
};
