import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Table, Badge, Collapse } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import moment from "moment";
const { Panel } = Collapse;
const columns = [
  {
    title: <div className="fs-12 text-gray">Risk</div>,
    dataIndex: "impact",
    key: "impact",
    render: (value) => (
      <div className="d-flex justify-content-center">
        {value !== "Medium" ? (
          <Badge status="error" />
        ) : (
          <Badge status="warning" />
        )}
      </div>
    ),
  },
  {
    title: <div className="fs-12 text-gray">Time (UTC +0)</div>,
    dataIndex: "date",
    key: "date",
    render: (value) => <div>{moment.utc(value).format("HH:mm:ss")}</div>,
  },
  {
    title: <div className="fs-12 text-gray">Currency</div>,
    dataIndex: "currency",
    key: "currency",
  },
  {
    title: <div className="fs-12 text-gray">Event</div>,
    dataIndex: "event",
    key: "event",
  },
  {
    title: <div className="fs-12 text-gray">Actual</div>,
    dataIndex: "actual",
    key: "actual",
    render: (value, record) => (
      <div>{value !== null && `${value}${record.unit}`}</div>
    ),
  },
  {
    title: <div className="fs-12 text-gray">Previous</div>,
    dataIndex: "previous",
    key: "previous",
    render: (value, record) => (
      <div>{value !== null && `${value}${record.unit}`}</div>
    ),
  },
  {
    title: <div className="fs-12 text-gray">Forecast</div>,
    dataIndex: "estimate",
    key: "estimate",
    render: (value, record) => (
      <div>{value !== null && `${value}${record.unit}`}</div>
    ),
  },
];
const renderExpand = (data) => {
  return (
    <div className="overflow-hidden">
      <div className="fs-12 font-weight-bold">Other details</div>
      <div className="row mt-2">
        <div className="col-3">
          <div className="fs-12 text-gray">Indicator</div>
          <div className="fs-12">{data.indicator}</div>
        </div>
        <div className="col-3">
          <div className="fs-12 text-gray">Ticker</div>
          <div className="fs-12">{data.ticker}</div>
        </div>
        <div className="col-3">
          <div className="fs-12 text-gray">Important </div>
          <div>
            {data.importance === 1 ? (
              <span className="fs-12">
                <Badge status="error" />
                High
              </span>
            ) : (
              <span className="fs-12">
                <Badge status="warning" />
                Medium
              </span>
            )}
          </div>
        </div>
        <div className="col-3">
          <div className="fs-12 text-gray">Source </div>
          <div className="fs-12">{data.source}</div>
        </div>
      </div>
      <div className="text-gray fs-12 mt-4">Description</div>

      <div className="fs-12 " style={{ width: "55rem" }}>
        {data.comment}
      </div>
    </div>
  );
};

export const TableCalendar = ({ dataCalendar }) => {
  return (
    <div className="economic">
      <Table
        columns={columns}
        // expandIconColumnIndex={7}
        // // expandIcon={() => }
        // expandable={{
        //   expandRowByClick: true,
        //   expandIcon: ({ expanded, onExpand, record }) =>
        //     expanded ? (
        //       <img
        //         src="/assets/images/icons/dashboard/arrow-up.svg"
        //         type="up"
        //         alt="arrow-up"
        //         style={{ fontSize: "20px" }}
        //         className="cursor-pointer"
        //         onClick={(e) => onExpand(record, e)}
        //       />
        //     ) : (
        //       <img
        //         src="/assets/images/icons/dashboard/arrow-down.svg"
        //         type="down"
        //         alt="arrow-down"
        //         style={{ fontSize: "20px" }}
        //         className="cursor-pointer"
        //         onClick={(e) => onExpand(record, e)}
        //       />
        //     ),

        //   expandedRowRender: (record) => renderExpand(record),
        //   rowExpandable: (record) => record.source !== "",
        // }}

        // pagination={tableParams.pagination}\
        pagination={{
          showSizeChanger: true,
        }}
        dataSource={dataCalendar}
      />
    </div>
  );
};

TableCalendar.propTypes = {
  dataCalendar: PropTypes.array,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TableCalendar);
