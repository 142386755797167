export default {
  en: {
    wecover: "We’ve covered you",
    wecover: "Recover Times",
    negative: {
      advantageNegativeBalanceProtection:
        "Advantage of Negative Balance Protection",
      worryForBeginners:
        "The worry for beginners is losing more than your initial investment, with Negative Balance Protection at IUX you will never lose more than your initial investment.",
      exampleExplanation:
        "Here is an example: Let's say you have a balance of $100 in your trading account, but due to a market spike, gap, or other unexpected event, you lose $150.\n\nYou don't need to pay $50 for IUX, IUX will cover the excess for you.",
      zeroStopLevelFeature:
        "The Zero Stop Level feature allows traders to set a minimum distance, known as the Stop Level, as close to the current market price as possible when placing or closing pending orders.",
      neverLoseOpportunity:
        "You will never lose any opportunity to set pending orders or stop losses and take profits. With Zero Stop Level, your trading system is free and can be executed as you please.",
    },
    stopOut: {
      title: "Negative Balance Protection",
      resetBalance: "Covered Losses",
      broken: "Reset to Zero",
      brokenTimes: "Times",
      des1: "You won’t losses more than what you have in your account. If your account goes negative due to a Stop Out, we’ll reset it to zero.",
      des2: "For example, if your trading account with $100 balance closes with a $120 loss, the account will have a negative balance of -$20. We’ll restore it to zero, so you won’t need to cover the loss. It’s an automatic reset, ensuring your funds always protected.",
    },
    zeroStopLevel: {
      title: "Zero Stop Level",
      buyLimit: "Buy limit -> below current ask by -1 point",
      sellLimit: "Sell limit -> above current bid by +1 point",
      butStop: "Buy stop -> above current ask by +1 point",
      sellStop: "Sell stop -> below current bid by -1 point",
      des1: "The Zero Stop Level feature allows traders to set a minimum distance, known as the Stop Level, as close to the current market price as possible when placing or closing pending orders.",
      des2: "You will never lose any opportunity to set pending orders or stop losses and take profits. With Zero Stop Level, your trading system is free and can be executed as you please.",
    },
  },
  th: {
    negative: {
      advantageNegativeBalanceProtection:
        "ข้อดีของ Negative Balance Protection",
      worryForBeginners:
        "ความกังวลสำหรับผู้เริ่มต้นคือการสูญเสียมากกว่าการลงทุนเริ่มแรกของคุณ ด้วย Negative Balance Protection ที่ IUX คุณจะไม่สูญเสียมากกว่าการลงทุนเริ่มแรกของคุณ",
      exampleExplanation:
        "นี่คือตัวอย่าง: สมมติว่าคุณมียอดคงเหลือ $100 ในบัญชีซื้อขายของคุณ แต่เนื่องจากตลาดพุ่งสูงขึ้น Gap หรือเหตุการณ์ที่ไม่คาดคิดอื่น ๆ คุณจึงสูญเสีย $150 \n\nคุณไม่จำเป็นต้องจ่ายเงิน 50 ดอลลาร์สำหรับ IUX เพราะ IUX จะรับผิดชอบส่วนเกินให้คุณ",
      zeroStopLevelFeature:
        " Zero Stop Level  ช่วยให้เทรดเดอร์สามารถกำหนดระยะห่างขั้นต่ำที่เรียกว่าระดับ Stop Level ให้ใกล้เคียงกับราคาตลาดปัจจุบันมากที่สุดเท่าที่จะเป็นไปได้เมื่อวางหรือปิดคำสั่งที่รอดำเนินการ",
      neverLoseOpportunity:
        "คุณจะไม่สูญเสียโอกาสในการตั้งค่า Pending Order หรือ Stop Loss และ Take Profit ด้วย Zero Stop Level ระบบการซื้อขายของคุณนั้นฟรีและสามารถดำเนินการได้ตามที่คุณต้องการ",
    },
    stopOut: {
      title: "การป้องกันยอดคงเหลือติดลบ",
      resetBalance: "การสูญเสียที่ครอบคลุม",
      broken: "รีเซ็ตเป็นศูนย์",
      brokenTimes: "ครั้ง",
      des1: "คุณจะไม่ขาดทุนมากกว่าที่คุณมีในบัญชีของคุณ หากบัญชีของคุณติดลบเนื่องจาก Stop Out เราจะรีเซ็ตให้เป็นศูนย์",
      des2: "ตัวอย่างเช่น หากบัญชีซื้อขายของคุณที่มียอดคงเหลือ $100 ปิดลงโดยขาดทุน $120 บัญชีนั้นจะมียอดคงเหลือติดลบ -$20 เราจะคืนค่าให้เป็นศูนย์ ดังนั้นคุณไม่จำเป็นต้องชดเชยการสูญเสีย เป็นการรีเซ็ตอัตโนมัติเพื่อให้มั่นใจว่าเงินของคุณจะได้รับการคุ้มครองอยู่เสมอ",
    },
    zeroStopLevel: {
      title: "Zero Stop Level",
      buyLimit: "Buy Limit ต่ำกว่าราคา  ask ปัจจุบันที่ -1 จุด",
      sellLimit: "Sell Limit สูงกว่าราคา bid ปัจจุบันที่ +1 จุด",
      butStop: "Buy Stop สูงกว่าราคา ask ปัจจุบันที่ +1 จุด",
      sellStop: "Sell Stop ต่ำกว่าราคา bid ปัจจุบันที่ -1",
      des1: "คุณลักษณะ Zero Stop Level ช่วยให้เทรดเดอร์กำหนดระยะห่างขั้นต่ำในอัตราที่ใกล้เคียงกับราคาตลาดมากที่สุดระหว่างราคาที่ต้องการและราคาตลาดปัจจุบัน หรือที่เรียกว่า Stop Level สำหรับทั้งการวางคำสั่งที่รอดำเนินการและการปิดคำสั่งที่รอดำเนินการ โดยเป็นไปตามเงื่อนไขดังต่อไปนี้: ประเภทคำสั่งที่รอดำเนินการ Stop Level",
      des2: "จุดยิ่ง Stop Level แคบมากขึ้นเท่าใด คุณก็จะยิ่งมีโอกาสในการทำกำไรมากขึ้นเท่านั้น คุณสมบัตินี้ทำให้คุณมั่นใจได้ว่าการดำเนินการคำสั่งซื้อขายที่รอดำเนินการ, Take Profit และ Stop Loss จะประสบความสำเร็จ",
    },
  },
  lo: {
    negative: {
      advantageNegativeBalanceProtection:
        "ຂໍ້ໄດ້ປຽບຂອງ Negative Balance Protection",
      worryForBeginners:
        "ຄວາມກັງວົນສໍາລັບຜູ້ເລີ່ມຕົ້ນແມ່ນສູນເສຍຫຼາຍກ່ວາການລົງທຶນເບື້ອງຕົ້ນຂອງທ່ານ, ດ້ວຍ Negative Balance Protection  IUX ທ່ານຈະບໍ່ສູນເສຍຫຼາຍກ່ວາການລົງທຶນເບື້ອງຕົ້ນຂອງທ່ານ.",
      exampleExplanation:
        "ນີ້ແມ່ນຕົວຢ່າງ: ໃຫ້ເວົ້າວ່າທ່ານມີຍອດເງິນຂອງ $ 100 ໃນບັນຊີການຄ້າຂອງທ່ານ, ແຕ່ເນື່ອງຈາກຕະຫຼາດເພີ່ມຂຶ້ນ, ຊ່ອງຫວ່າງ, ຫຼືເຫດການທີ່ບໍ່ຄາດຄິດອື່ນໆ, ທ່ານຈະສູນເສຍ $ 150.\n\nທ່ານບໍ່ຈໍາເປັນຕ້ອງຈ່າຍເງິນ $50 ສໍາລັບ IUX, IUX ຈະກວມເອົາສ່ວນເກີນສໍາລັບທ່ານ.",
      zeroStopLevelFeature:
        "ຄຸນນະສົມບັດ Zero Stop Level ອະນຸຍາດໃຫ້ພໍ່ຄ້າສາມາດກໍານົດໄລຍະຫ່າງຕໍ່າສຸດທີ່ທີ່ເອີ້ນວ່າ Stop Level, ຢູ່ໃກ້ກັບລາຄາຕະຫຼາດໃນປະຈຸບັນເທົ່າທີ່ເປັນໄປໄດ້ໃນເວລາທີ່ວາງຫຼືປິດຄໍາສັ່ງທີ່ຍັງຄ້າງຢູ່.",
      neverLoseOpportunity:
        "ທ່ານຈະບໍ່ສູນເສຍໂອກາດໃດໆທີ່ຈະກໍານົດ pending orders ຫຼື stop losses ແລະ take profits. ດ້ວຍ Zero Stop Level, ລະບົບການຊື້ຂາຍຂອງທ່ານແມ່ນບໍ່ເສຍຄ່າແລະສາມາດປະຕິບັດໄດ້ຕາມໃຈ.",
    },
    stopOut: {
      title: "ການປົກປ້ອງຍອດເງິນຍັງເຫຼືອຕິດລົບ",
      resetBalance: "ການສູນເສຍທີ່ໄດ້ຮັບການຄຸ້ມຄອງ",
      broken: "ຣີເຊັດເປັນສູນ",
      brokenTimes: "ເທື່ອ",
      des1: "ທ່ານຈະບໍ່ສູນເສຍຫຼາຍກ່ວາສິ່ງທີ່ທ່ານມີຢູ່ໃນບັນຊີຂອງທ່ານ ຖ້າບັນຊີຂອງທ່ານຕິດລົບຍ້ອນ Stop Out ພວກເຮົາຈະຕັ້ງມັນເປັນສູນ",
      des2: "ຕົວຢ່າງ: ຖ້າບັນຊີການຊື້ຂາຍຂອງທ່ານທີ່ມີຍອດເງິນ 100 ໂດລາ ປິດດ້ວຍການສູນເສຍ 120 ໂດລາ ບັນຊີຈະມີຍອດເງິນຕິດລົບເປັນ -20 ໂດລາ ພວກ​ເຮົາ​ຈະ​ຟື້ນ​ຟູ​ໃຫ້​ເປັນ​ສູນ​ ດັ່ງ​ນັ້ນ​ທ່ານ​ຈະ​ບໍ່​ຈໍາ​ເປັນ​ຕ້ອງ​ປົກ​ປິດ​ການ​ສູນ​ເສຍ​ ມັນ​ເປັນ​ການ​ປັບ​ອັດ​ຕະ​ໂນ​ມັດ​ການ​ຮັບ​ປະ​ກັນ​ການ​ປົກ​ປ້ອງ​ເງິນ​ຂອງ​ທ່ານ​ສະ​ເຫມີ",
    },
    zeroStopLevel: {
      title: "Zero Stop Level",
      buyLimit: "Buy limit -> ຕໍ່າກວ່າລາຄາ ask ໃນປັດຈຸບັນ -1 ຈຸດ",
      sellLimit: "Sell limit -> ຂ້າງເທິງລາຄາ bid ໃນປັດຈຸບັນໂດຍ +1 ຈຸດ",
      butStop: "Buy stop -> ຂ້າງເທິງລາຄາ ask ໃນປັດຈຸບັນໂດຍ +1 ຈຸດ",
      sellStop: "Sell stop -> ຂ້າງລຸ່ມນີ້ລາຄາ bid ໃນປັດຈຸບັນໂດຍ -1 ຈຸດ",
      des1: "ຄຸນນະສົມບັດ Zero Stop Level ອະນຸຍາດໃຫ້ພໍ່ຄ້າສາມາດກໍານົດໄລຍະຫ່າງຂັ້ນຕ່ໍາ, ທີ່ເອີ້ນວ່າ Stop Level, ຢູ່ໃກ້ກັບລາຄາຕະຫຼາດໃນປະຈຸບັນເທົ່າທີ່ເປັນໄປໄດ້ໃນເວລາທີ່ວາງຫຼືປິດຄໍາສັ່ງທີ່ຍັງຄ້າງຢູ່. ນີ້ແມ່ນກົດລະບຽບ:",
      des2: "Stop Level ທີ່ເຄັ່ງຄັດກວ່າເຮັດໃຫ້ໂອກາດກໍາໄລຫຼາຍ, ຮັບປະກັນການປະຕິບັດຄໍາສັ່ງທີ່ຍັງຄ້າງ, take profits, ແລະ stop losses.",
    },
  },
  hi: {
    negative: {
      advantageNegativeBalanceProtection: "Negative Balance Protection का लाभ",
      worryForBeginners:
        "शुरुआती लोगों के लिए चिंता की बात यह है कि वे अपने शुरुआती निवेश से ज़्यादा खो न दें, IUX में Negative Balance Protection के साथ आप कभी भी अपने शुरुआती निवेश से ज़्यादा नहीं खोएँगे।",
      exampleExplanation:
        "यहाँ एक उदाहरण दिया गया है: मान लें कि आपके ट्रेडिंग खाते में $100 का बैलेंस है, लेकिन बाज़ार में उछाल, गैप या अन्य अप्रत्याशित घटना के कारण, आप $150 खो देते हैं।\n\nआपको IUX के लिए $50 का भुगतान करने की ज़रूरत नहीं है, IUX आपके लिए अतिरिक्त राशि का भुगतान करेगा।",
      zeroStopLevelFeature:
        "Zero Stop Level सुविधा व्यापारियों को लंबित ऑर्डर देते या बंद करते समय वर्तमान बाज़ार मूल्य के जितना संभव हो सके उतना करीब एक न्यूनतम दूरी, जिसे Stop Level के रूप में जाना जाता है, सेट करने की अनुमति देती है।",
      neverLoseOpportunity:
        "आप Pending Order या Stop Loss और Take Profit सेट करने का कोई भी अवसर कभी नहीं खोएँगे। Zero Stop Level के साथ, आपका ट्रेडिंग सिस्टम मुफ़्त है और इसे आप अपनी इच्छानुसार निष्पादित कर सकते हैं।",
    },
    stopOut: {
      title: "नकारात्मक संतुलन संरक्षण",
      resetBalance: "कवर किया गया घाटा",
      broken: "शून्य",
      brokenTimes: "बार पर रीसेट करें",
      des1: "आपके खाते में जो राशि है उससे अधिक आपको हानि नहीं होगी। यदि आपका खाता Stop Out के कारण नकारात्मक हो जाता है, तो हम इसे शून्य पर रीसेट कर देंगे।",
      des2: "उदाहरण के लिए, यदि आपका $100 शेष वाला ट्रेडिंग खाता $120 हानि के साथ बंद होता है, तो खाते में -$20 का नकारात्मक शेष होगा। हम इसे शून्य पर पुनर्स्थापित कर देंगे, इसलिए आपको नुकसान की भरपाई करने की आवश्यकता नहीं होगी। यह एक स्वचालित रीसेट है, जो यह सुनिश्चित करता है कि आपका धन हमेशा सुरक्षित रहे।",
    },
    zeroStopLevel: {
      title: "Zero Stop Level",
      buyLimit: "Buy limit -> वर्तमान ask मूल्य से -1 अंक नीचे",
      sellLimit: "Sell limit -> वर्तमान bid मूल्य से +1 अंक ऊपर",
      butStop: "Buy stop -> वर्तमान ask मूल्य से +1 अंक ऊपर",
      sellStop: "Sell Stop -> वर्तमान bid मूल्य से -1 अंक नीचे",
      des1: "Zero Stop Level सुविधा व्यापारियों को लंबित ऑर्डर देते या बंद करते समय न्यूनतम दूरी निर्धारित करने की अनुमति देती है, जिसे Stop Level के रूप में जाना जाता है, जो मौजूदा बाजार मूल्य के जितना करीब हो सके। यहाँ नियम हैं:",
      des2: "एक सख्त Stop Level अधिक लाभ के अवसर बनाता है, जिससे लंबित ऑर्डर, take profits, और stop losses का सफल निष्पादन सुनिश्चित होता है।",
    },
  },
  ms: {
    negative: {
      advantageNegativeBalanceProtection:
        "Kelebihan Negative Balance Protection",
      worryForBeginners:
        "Kebimbangan bagi pemula ialah kerugian lebih daripada pelaburan awal anda, dengan Negative Balance Protection di IUX anda tidak akan rugi lebih daripada pelaburan awal anda.",
      exampleExplanation:
        "Berikut ialah contoh: Katakan anda mempunyai baki $100 dalam akaun dagangan anda, tetapi disebabkan lonjakan pasaran, jurang atau peristiwa lain yang tidak dijangka, anda kehilangan $150.\n\nAnda tidak perlu membayar $50 untuk IUX, IUX akan menampung lebihan untuk anda.",
      zeroStopLevelFeature:
        "Ciri Zero Stop Level membolehkan pedagang menetapkan jarak minimum, dikenali sebagai Stop Level, sehampir mungkin dengan harga pasaran semasa semasa meletakkan atau menutup pesanan yang belum selesai.",
      neverLoseOpportunity:
        "Anda tidak akan kehilangan sebarang peluang untuk menetapkan pesanan belum selesai atau menghentikan kerugian dan mengambil keuntungan. Dengan Zero Stop Level, sistem perdagangan anda adalah percuma dan boleh dilaksanakan mengikut kehendak anda.",
    },
    stopOut: {
      title: "Perlindungan Imbangan Negatif",
      resetBalance: "Kerugian Dilindungi",
      broken: "Tetapkan semula kepada Sifar",
      brokenTimes: "Kali",
      des1: "Anda tidak akan rugi lebih daripada apa yang anda ada dalam akaun anda. Jika akaun anda menjadi negatif disebabkan Stop Out, kami akan menetapkan semula akaun anda kepada sifar.",
      des2: "Sebagai contoh, jika akaun dagangan anda dengan baki $100 ditutup dengan kerugian $120, akaun tersebut akan mempunyai baki negatif sebanyak -$20. Kami akan memulihkannya kepada sifar, jadi anda tidak perlu menanggung kerugian. Ia adalah tetapan semula automatik, memastikan dana anda sentiasa dilindungi.",
    },
    zeroStopLevel: {
      title: "Zero Stop Level",
      buyLimit: "Buy limit -> di bawah harga ask semasa sebanyak -1 mata",
      sellLimit: "Sell limit -> melebihi harga bid semasa sebanyak +1 mata",
      butStop: "Buy stop -> di atas harga ask semasa sebanyak +1 mata",
      sellStop: "Sell stop -> di bawah harga bid semasa sebanyak -1 mata",
      des1: "Ciri Zero Stop Level membolehkan pedagang menetapkan jarak minimum, dikenali sebagai Stop Level, sehampir mungkin dengan harga pasaran semasa apabila meletakkan atau menutup pesanan yang belum selesai. Berikut adalah peraturannya:",
      des2: "Stop Level yang lebih ketat menghasilkan lebih banyak peluang keuntungan, memastikan pelaksanaan pesanan tertunda dengan jayanya, take profits dan stop losses.",
    },
  },
  id: {
    negative: {
      advantageNegativeBalanceProtection:
        "Keuntungan dari Negative Balance Protection",
      worryForBeginners:
        "Kekhawatiran bagi pemula adalah kehilangan lebih dari investasi awal Anda, dengan Negative Balance Protection di IUX Anda tidak akan pernah kehilangan lebih dari investasi awal Anda.",
      exampleExplanation:
        "Berikut ini contohnya: Katakanlah Anda memiliki saldo $100 di akun trading Anda, namun karena lonjakan pasar, gap, atau kejadian tak terduga lainnya, Anda kehilangan $150.\n\nAnda tidak perlu membayar $50 untuk IUX, IUX akan menanggung kelebihannya untuk Anda.",
      zeroStopLevelFeature:
        "Fitur Zero Stop Level memungkinkan pedagang untuk menetapkan jarak minimum, yang dikenal sebagai Stop Level, sedekat mungkin dengan harga pasar saat ini ketika menempatkan atau menutup pending order.",
      neverLoseOpportunity:
        "Anda tidak akan pernah kehilangan kesempatan untuk memasang pending order atau menghentikan kerugian dan mengambil keuntungan. Dengan Zero Stop Level, sistem perdagangan Anda gratis dan dapat dijalankan sesuka Anda.",
    },
    stopOut: {
      title: "Perlindungan Saldo Negatif",
      resetBalance: "Kerugian yang Ditanggung",
      broken: "Reset ke Nol",
      brokenTimes: "Kali",
      des1: "Anda tidak akan mengalami kerugian lebih dari apa yang ada di akun Anda. Jika akun Anda menjadi negatif karena Stop Out, kami akan meresetnya ke nol.",
      des2: "Misalnya, jika akun trading Anda dengan saldo $100 ditutup dengan kerugian $120, akun tersebut akan memiliki saldo negatif -$20. Kami akan mengembalikannya ke nol, jadi Anda tidak perlu menanggung kerugiannya. Ini adalah pengaturan ulang otomatis, memastikan dana Anda selalu terlindungi.",
    },
    zeroStopLevel: {
      title: "Zero Stop Level",
      buyLimit: "Buy limit -> di bawah harga ask saat ini sebesar -1 poin",
      sellLimit: "Sell limit -> di atas harga bid saat ini sebesar +1 poin",
      butStop: "Buy stop -> di atas harga ask saat ini sebesar +1 poin",
      sellStop: "Sell stop -> di bawah harga bid saat ini sebesar -1 poin",
      des1: "Fitur Zero Stop Level memungkinkan pedagang untuk menetapkan jarak minimum, yang dikenal sebagai Stop Level, sedekat mungkin dengan harga pasar saat ini ketika menempatkan atau menutup pending order. Berikut aturannya:",
      des2: "Stop Level yang lebih ketat menghasilkan lebih banyak peluang keuntungan, memastikan keberhasilan eksekusi pending order, take profits, dan stop losses.",
    },
  },
  zh: {
    negative: {},
    stopOut: {
      title: "负余额保护",
      resetBalance: "承保损失",
      broken: "重置为零",
      brokenTimes: "次",
      des1: "您的损失不会超过您帐户中的金额。 如果您的账户因 Stop Out 而变为负数，我们会将其重置为零。",
      des2: "例如，如果您的余额为 100 美元的交易账户因亏损 120 美元而关闭，则该账户的负余额将为 - 20 美元。 我们会将其恢复为零，因此您无需承担损失。 这是自动重置，确保您的资金始终受到保护。",
    },
    zeroStopLevel: {
      title: "Zero Stop Level",
      buyLimit: "Buy limit -> 低于当前 ask 价格 -1 点",
      sellLimit: "Sell limit -> 比当前 bid 价格高 +1 个点",
      butStop: "Buy stop -> 高于当前 ask 价格 +1 点",
      sellStop: "Sell stop -> 低于当前 bid 价格 -1 点",
      des1: "Zero Stop Level 功能允许交易者在下达或关闭挂单时设置一个最小距离（称为 Stop Level），尽可能接近当前市场价格。 规则如下：",
      des2: "更严格的 Stop Level 可以带来更多盈利机会，确保挂单、take profits 和 stop losses 的成功执行。",
    },
  },
  vi: {
    negative: {
      advantageNegativeBalanceProtection:
        "Ưu điểm của Negative Balance Protection",
      worryForBeginners:
        "Nỗi lo của người mới bắt đầu là mất nhiều hơn khoản đầu tư ban đầu của bạn, với Negative Balance Protection tại IUX, bạn sẽ không bao giờ mất nhiều hơn khoản đầu tư ban đầu của mình.",
      exampleExplanation:
        "Đây là một ví dụ: Giả sử bạn có số dư 100 USD trong tài khoản giao dịch của mình, nhưng do thị trường tăng đột biến, chênh lệch hoặc sự kiện bất ngờ khác, bạn mất 150 USD.\n\nBạn không cần phải trả $50 cho IUX, IUX sẽ chi trả phần vượt quá cho bạn.",
      zeroStopLevelFeature:
        "Tính năng Zero Stop Level cho phép các nhà giao dịch đặt khoảng cách tối thiểu, được gọi là Stop Level, càng gần với giá thị trường hiện tại càng tốt khi đặt hoặc đóng các lệnh chờ xử lý.",
      neverLoseOpportunity:
        "Bạn sẽ không bao giờ mất bất kỳ cơ hội nào để đặt Pending Order hoặc Stop Loss và Take Profit. Với Zero Stop Level, hệ thống giao dịch của bạn hoàn toàn miễn phí và có thể được thực hiện theo ý muốn.",
    },
    stopOut: {
      title: "Bảo vệ số dư âm",
      resetBalance: "Khoản lỗ được bảo hiểm",
      broken: "Đặt lại về 0",
      brokenTimes: "lần",
      des1: "Bạn sẽ không thua lỗ nhiều hơn số tiền bạn có trong tài khoản của mình. Nếu tài khoản của bạn bị âm do Ngưng giao dịch, chúng tôi sẽ đặt lại về 0.",
      des2: "Ví dụ: nếu tài khoản giao dịch của bạn có số dư 100 USD đóng cửa với khoản lỗ 120 USD, tài khoản sẽ có số dư âm là -20 USD. Chúng tôi sẽ khôi phục nó về 0, vì vậy bạn sẽ không cần phải bù đắp khoản lỗ. Đó là tính năng thiết lập lại tự động, đảm bảo tiền của bạn luôn được bảo vệ.",
    },
    zeroStopLevel: {
      title: "Zero Stop Level",
      buyLimit: "Buy limit -> thấp hơn giá ask hiện tại -1 điểm",
      sellLimit: "Sell limit -> cao hơn giá bid hiện tại +1 điểm",
      butStop: "Buy stop -> cao hơn giá ask hiện tại +1 điểm",
      sellStop: "Sell stopp -> thấp hơn giá bid hiện tại -1 điểm",
      des1: "Tính năng Zero Stop Level cho phép các nhà giao dịch đặt khoảng cách tối thiểu, được gọi là Stop Level, càng gần với giá thị trường hiện tại càng tốt khi đặt hoặc đóng các lệnh chờ xử lý. Đây là các quy tắc:",
      des2: "Stop Level chặt chẽ hơn tạo ra nhiều cơ hội lợi nhuận hơn, đảm bảo thực hiện thành công các lệnh đang chờ xử lý, take profits và stop losses.",
    },
  },
  ko: {
    negative: {},
    stopOut: {
      title: "마이너스 잔액 보호",
      resetBalance: "보장된 손실",
      broken: "0으로 재설정",
      brokenTimes: "회",
      des1: "귀하는 귀하의 계좌에 있는 것보다 더 많은 손실을 입지 않을 것입니다. Stop Out으로 인해 귀하의 계정이 마이너스가 되면 해당 계정을 0으로 재설정해 드립니다.",
      des2: "예를 들어, 잔액이 100달러인 거래 계좌가 120달러의 손실로 닫히면 해당 계좌의 잔액은 -$20가 됩니다. 0으로 복원해 드리므로 손실을 부담하실 필요가 없습니다. 자동 재설정이므로 자금이 항상 보호됩니다.",
    },
    zeroStopLevel: {
      title: "Zero Stop Level",
      buyLimit: "Buy limit -> 현재 ask 가격보다 -1포인트 낮음",
      sellLimit: "Sell limit -> 현재 bid 가격보다 +1 포인트 높음",
      butStop: "Buy stop -> 현재 ask 가격보다 +1 포인트 높음",
      sellStop: "Sell Stop -> 현재 bid 가격보다 -1 포인트 낮음",
      des1: "Zero Stop Level 기능을 사용하면 거래자는 보류 주문을 하거나 마감할 때 Stop Level이라고 하는 최소 거리를 현재 시장 가격에 최대한 가깝게 설정할 수 있습니다. 규칙은 다음과 같습니다.",
      des2: "Stop Level이 더 엄격할수록 더 많은 수익 기회를 창출하여 대기 중인 주문, take profits 및 stop losses의 성공적인 실행을 보장합니다.",
    },
  },
  fil: {
    negative: {
      advantageNegativeBalanceProtection:
        "Bentahe ng Negative Balance Protection",
      worryForBeginners:
        "Ang pag-aalala para sa mga baguhan ay nalulugi nang higit pa sa iyong paunang puhunan, sa Negative Balance Protection sa IUX, hinding-hindi ka mawawalan ng higit sa iyong paunang puhunan.",
      exampleExplanation:
        "Narito ang isang halimbawa: Sabihin nating mayroon kang balanse na $100 sa iyong trading account, ngunit dahil sa pagtaas ng market, gap, o iba pang hindi inaasahang kaganapan, nawalan ka ng $150.\n\nHindi mo kailangang magbayad ng $50 para sa IUX, sasagutin ng IUX ang labis para sa iyo.",
      zeroStopLevelFeature:
        "Ang tampok na Zero Stop Level ay nagbibigay-daan sa mga mangangalakal na magtakda ng pinakamababang distansya, na kilala bilang Stop Level, na mas malapit sa kasalukuyang presyo sa merkado hangga't maaari kapag naglalagay o nagsasara ng mga nakabinbing order.",
      neverLoseOpportunity:
        "Ang tampok na Zero Stop Level ay nagbibigay-daan sa mga mangangalakal na magtakda ng pinakamababang distansya, na kilala bilang Stop Level, na mas malapit sa kasalukuyang presyo sa merkado hangga't maaari kapag naglalagay o nagsasara ng mga nakabinbing order.",
    },
    stopOut: {
      title: "Proteksyon sa Negatibong Balanse",
      resetBalance: "Sakop na Pagkalugi",
      broken: "I-reset sa Zero",
      brokenTimes: "Times",
      des1: "Hindi ka mawawalan ng higit sa kung ano ang mayroon ka sa iyong account. Kung naging negatibo ang iyong account dahil sa isang Stop Out, ire-reset namin ito sa zero.",
      des2: "Halimbawa, kung ang iyong trading account na may $100 na balanse ay magsasara nang may $120 na pagkawala, ang account ay magkakaroon ng negatibong balanse na -$20. Ire-restore namin ito sa zero, kaya hindi mo na kailangang takpan ang pagkawala. Ito ay isang awtomatikong pag-reset, na tinitiyak na palaging protektado ang iyong mga pondo.",
    },
    zeroStopLevel: {
      title: "Zero Stop Level",
      buyLimit:
        "Buy limit -> mas mababa sa kasalukuyang ask na presyo ng -1 puntos",
      sellLimit:
        "Sell limit -> mas mataas sa kasalukuyang presyo ng bid nang +1 puntos",
      butStop:
        "Buy stop -> mas mataas sa kasalukuyang ask na presyo nang +1 puntos",
      sellStop:
        "Sell stop -> mas mababa sa kasalukuyang presyo ng bid ng -1 point",
      des1: "Ang tampok na Zero Stop Level ay nagbibigay-daan sa mga mangangalakal na magtakda ng pinakamababang distansya, na kilala bilang Stop Level, nang mas malapit sa kasalukuyang presyo sa merkado hangga't maaari kapag naglalagay o nagsasara ng mga nakabinbing order. Narito ang mga patakaran:",
      des2: "Ang mas mahigpit na Stop Level ay nakakakuha ng mas maraming pagkakataon sa kita, na tinitiyak ang matagumpay na pagpapatupad ng mga nakabinbing order, take profits, at stop losses.",
    },
  },
  ja: {
    negative: {
      advantageNegativeBalanceProtection: "マイナス残高保護の利点",
      worryForBeginners:
        "初心者の心配は、元本を超えて損失を出すことですが、IUX でのマイナス残高保護があれば、初期投資額以上の損失は絶対にありません。",
      exampleExplanation:
        "例えば、取引口座に$100の残高があるとします。しかし、市場の急騰、ギャップ、または他の予期しないイベントにより、$150を失うことがあります。\n\nIUXに50ドルを支払う必要はありません。IUXが余剰分をカバーします。",
      zeroStopLevelFeature:
        "ゼロストップレベル機能により、トレーダーは未決済注文を出す際、またはクローズする際に、市場価格にできるだけ近い位置にストップレベル として知られる最小の距離を設定することができます。",
      neverLoseOpportunity:
        "未決済注文やストップロス、利益確定注文を設定する機会を失うことはありません。ゼロストップレベルにより、あなたの取引システムは自由で、ご希望通りに実行できます。",
    },
    stopOut: {
      title: "マイナス残高保護",
      resetBalance: "補償される損失",
      broken: "ゼロにリセット",
      brokenTimes: "回",
      des1: "口座にある以上の損失を被ることはありません。 Stop Out により口座がマイナスになった場合は、ゼロにリセットされます。",
      des2: "例、残高 100 ドルの取引口座が 120 ドルの損失で終了した場合、口座の残高は -20 ドルのマイナスになります。 ゼロに戻しますので、損失を補填する必要はありません。 自動リセットなので、資金は常に保護されます。",
    },
    zeroStopLevel: {
      title: "Zero Stop Level",
      buyLimit: "Buy  limit -> 現在の ask 価格を -1 ポイント下回ります",
      sellLimit: "Sell limit -> 現在の bid 価格を +1 ポイント上回ります",
      butStop: "Buy stop -> 現在の ask 価格を +1 ポイント上回る",
      sellStop: "Sell stop -> 現在の bid 価格を -1 ポイント下回る",
      des1: "Zero Stop Level 機能を使用すると、トレーダーは未決注文を発注またはクローズするときに、Stop Level として知られる最小距離を現在の市場価格にできるだけ近づけて設定できます。 ルールは次のとおりです。",
      des2: "Stop Level がより緊密になると、より多くの利益機会が得られ、未決注文、take profits、および stop losses の確実な実行が確実に成功します。",
    },
  },
  es: {
    wecover: "Te tenemos cubierto",
    recoverTimes: "Tiempos de recuperación",
    negative: {
      advantageNegativeBalanceProtection:
        "Ventaja de la Protección de Balance Negativo",
      worryForBeginners:
        "La preocupación para los principiantes es perder más que su inversión inicial, con la Protección de Balance Negativo en IUX nunca perderás más que tu inversión inicial.",
      exampleExplanation:
        "Aquí hay un ejemplo: Supongamos que tienes un saldo de $100 en tu cuenta de trading, pero debido a un pico del mercado, una brecha u otro evento inesperado, pierdes $150.\n\nNo necesitas pagar $50 a IUX, IUX cubrirá el exceso por ti.",
      zeroStopLevelFeature:
        "La característica de Nivel de Parada Cero permite a los traders establecer una distancia mínima, conocida como el Nivel de Parada, tan cerca del precio de mercado actual como sea posible al colocar o cerrar órdenes pendientes.",
      neverLoseOpportunity:
        "Nunca perderás la oportunidad de establecer órdenes pendientes o stop losses y take profits. Con el Nivel de Parada Cero, tu sistema de trading es libre y puede ejecutarse como desees.",
    },
    stopOut: {
      title: "Protección de Balance Negativo",
      resetBalance: "Pérdidas Cubiertas",
      broken: "Restablecido a Cero",
      brokenTimes: "Veces",
      des1: "No perderás más de lo que tienes en tu cuenta. Si tu cuenta se vuelve negativa debido a un Stop Out, la restableceremos a cero.",
      des2: "Por ejemplo, si tu cuenta de trading con un saldo de $100 cierra con una pérdida de $120, la cuenta tendrá un saldo negativo de -$20. La restauraremos a cero, por lo que no tendrás que cubrir la pérdida. Es un reinicio automático, asegurando que tus fondos siempre estén protegidos.",
    },
    zeroStopLevel: {
      title: "Nivel de Parada Cero",
      buyLimit: "Límite de compra -> por debajo del ask actual en -1 punto",
      sellLimit: "Límite de venta -> por encima del bid actual en +1 punto",
      butStop: "Stop de compra -> por encima del ask actual en +1 punto",
      sellStop: "Stop de venta -> por debajo del bid actual en -1 punto",
      des1: "La característica de Nivel de Parada Cero permite a los traders establecer una distancia mínima, conocida como el Nivel de Parada, tan cerca del precio de mercado actual como sea posible al colocar o cerrar órdenes pendientes.",
      des2: "Nunca perderás la oportunidad de establecer órdenes pendientes o stop losses y take profits. Con el Nivel de Parada Cero, tu sistema de trading es libre y puede ejecutarse como desees.",
    },
  },
};
