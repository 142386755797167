import React from "react";

const locale = require("react-redux-i18n").I18n;

function NegativeBalanceProtection() {
  return (
    <div>
      <div className="nbp-bg-swap-status p-4">
        <h6 className="fw-700">{locale.t("zeroStopLevel.title")}</h6>
        <div className="mt-3 fs-12 text-1E1E1E">
          {locale.t("negative.zeroStopLevelFeature")}
        </div>
        <div className="mt-3 fs-12  text-1E1E1E d-flex justify-center">
          <img
            src={`/assets/images/benefits/negative/zero-stop/${localStorage.getItem(
              "locale"
            )}/stoplevel.png`}
            width={"100%"}
            // className="w-100 w-lg-auto"
            // width={"100%"}
            style={{ maxWidth: 496 }}
          />
        </div>
        <div className="mt-3 fs-12 text-1E1E1E">
          {locale.t("negative.neverLoseOpportunity")}
        </div>
      </div>
    </div>
  );
}

export default NegativeBalanceProtection;
