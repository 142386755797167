import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Divider, Button, Form, Input, Tooltip, Row, Col, Badge } from "antd";
import { post } from "../../../../helper/request";
import B2binpay from "./B2binpay";
import PaymentCard from "../PaymentCard";
import { CheckCerrency } from "../../../../components/checkcurrency";
import { ClipLoader } from "react-spinners";
import { InfoCircleOutlined } from "@ant-design/icons";
import { fCurrencySymbol } from "../../../../helper/formatNumber";
import { renderApiDomain } from "../../../../helper/renderApiDomain";

const locale = require("react-redux-i18n").I18n;
function DepositThirdStep(props) {
  const { paymentId, paymentKey, payment_config, payment_name } = props;
  const { minimum_deposit, maximum_deposit } = payment_config;
  const [form] = Form.useForm();
  const [account, setAccount] = useState("");
  const payment = useSelector(({ user }) => user.bank);
  const accountlist = useSelector(({ account }) => account.accountTypeList);
  const accountDetail = accountlist?.find(
    (e, i) => e.name === account && e.type === "real"
  );
  const profileCountry = useSelector((state) => state.user.profileCountry);
  const [amount, setAmount] = useState("");
  const [suffix, setSuffix] = useState("");
  const [prefix, setPrefix] = useState("");
  const [loading, setLoading] = useState(true);
  const [countryId, setCountryId] = useState(null);
  const [rate, setRate] = useState(0);
  const removeLeadingZeros = (value) => {
    return value.replace(/^0+/, "");
  };
  useEffect(() => {
    setLoading(true);
    setAccount(props.account.account_type);
  }, []);
  useEffect(() => {
    // setAccountSelected(props.account.id);
    setCountryId(props?.user?.profile?.country_id);
    if (accountDetail) {
      if (paymentKey === "alphapo") {
        post(`${renderApiDomain()}deposit/alphapo/pairs`, {
          currency_from: "",
          type: "deposit",
        })
          .then(({ data: { data } }) => {
            // setAlphaSymbol(data);
            setRate(parseFloat(data.data[0].rate_to));
            props.onsetRate(data.data[0].rate_to);
            setSuffix(data.data[0].currency_to.currency);
            setPrefix(data.data[0].currency_from.currency);
            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      } else {
        post(`${renderApiDomain()}payment/rate`, {
          payment_id: paymentId,
          type: "deposit",
        })
          .then(({ data }) => {
            setRate(data.data.rate);
            props.onsetRate(data.data.rate);
            let miniAcc = accountDetail?.minimum_deposit * data.data.rate;
            let minipayment = minimum_deposit;
            let isCondition = minipayment > miniAcc ? minipayment : miniAcc;
            form.setFieldsValue({
              amount: Math.ceil(isCondition),
            });

            setAmount(Math.ceil(isCondition));
            props.onChangeAmount(Math.ceil(isCondition));

            setLoading(false);
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
          });
      }
    }
  }, [account]);
  const returnResultAmount = () => {
    let a = 0;
    if (paymentKey === "alphapo") {
      if (isNaN(props.amount * parseFloat(rate))) {
        a = 0;
      } else {
        a = (props.amount * parseFloat(rate)).toFixed(2);
      }
    } else {
      // if (countryId === 8) {
      //   if (isNaN(props.amount / parseFloat(rate))) {
      //     a = 0;
      //   } else {
      //     a = (props.amount / parseFloat(rate)).toFixed(2);
      //   }
      // } else {
      if (isNaN(props.amount / parseFloat(rate))) a = 0;
      else if (props.account.account_type === "Cent")
        a = ((props.amount / parseFloat(rate)) * 100).toFixed(2);
      else a = (props.amount / parseFloat(rate)).toFixed(2);
      // }
    }
    return parseFloat(+a)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  function checkDeposit(rule, value) {
    if (paymentKey === "alphapo") {
      if (account === "ECN") {
        if (value * rate >= 200) {
          return Promise.resolve();
        }
        return Promise.reject(locale.t("deposit_error.ecn"));
      }
      // else if (account === "Raw") {
      //   if (value * rate >= accountDetail?.minimum_deposit) {
      //     return Promise.resolve();
      //   }
      //   return Promise.reject(
      //     ` ${locale.t("withdraw_error.t_condition")}
      //     `
      //   );
      // }
      //  else if (account === "Pro") {
      //   if (value * rate >= accountDetail?.minimum_deposit) {
      //     return Promise.resolve();
      //   }
      //   return Promise.reject(
      //     ` ${locale.t("withdraw_error.t_condition")}
      //     `
      //   );
      // }
      else {
        if (value * rate >= accountDetail?.minimum_deposit) {
          return Promise.resolve();
        }

        return Promise.reject(locale.t("deposit_error.amount"));
      }
    } else {
      // if (account === "ECN") {
      //   if (value >= 200 * rate) {
      //     return Promise.resolve();
      //   }
      //   return Promise.reject(locale.t("deposit_error.ecn"));
      // } else if (account === "Raw") {
      //   if (value >= 1000 * rate) {
      //     return Promise.resolve();
      //   }
      //   return Promise.reject(
      //     `${locale.t("deposit_error.h2p")} ${locale.t(
      //       "withdraw_error.t_condition"
      //     )}
      //     `
      //   );
      // } else if (account === "PRO") {
      //   if (value >= 1000 * rate) {
      //     return Promise.resolve();
      //   }
      //   return Promise.reject(
      //     `${locale.t("deposit_error.h2p")} ${locale.t(
      //       "withdraw_error.t_condition"
      //     )}
      //     `
      //   );
      // } else {
      if (
        value / rate < accountDetail?.minimum_deposit ||
        value < minimum_deposit ||
        value === ""
      ) {
        return Promise.reject(
          ` ${locale.t("withdraw_error.t_condition")}
            `
        );
      } else if (value > maximum_deposit) {
        return Promise.reject(
          `${locale.t("deposit_error.nomore")} 
         ${CheckCerrency(props?.user?.profile?.country_id)?.symbol}${parseFloat(
            maximum_deposit
          ).toLocaleString()} `
        );
      } else {
        return Promise.resolve();
      }
    }
  }

  const handleKeyPress = (event) => {
    const input = event.target.value + event.key;

    // Regular expression to check if the input is a two-digit number with optional decimal point
    const regex = /^\d*\.?\d{0,2}$/;

    if (!regex.test(input)) {
      event.preventDefault(); // Prevents entering the invalid key
    } else {
    }
  };
  const addDefaultSrc = (ev) => {
    ev.target.src = "/assets/images/icons/globe-black.svg";
  };
  return (
    <div className="user">
      {loading ? (
        <div
          className="h-100 d-flex justify-content-center align-items-center flex-column my-5"
          style={{ height: 500 }}
        >
          <ClipLoader
            color="#36d7b7"
            size={72}
            cssOverride={{ borderWidth: 8 }}
          />
        </div>
      ) : (
        <>
          {paymentKey === "b2binpay" ? (
            <B2binpay
              account={props.account}
              amount={amount}
              paymentKey={paymentKey}
              paymentId={paymentId}
              onBackStep={props.onBackStep}
              onSuccess={props.onSuccess}
            />
          ) : (
            <>
              <div className="fs-20 font-weight-bold mb-3">
                2.{locale.t("deposit_step.second_step.title")}
              </div>
              <div className="text-lightgray-iux  mb-2">
                {locale.t("dashboardWallet.account")}:
              </div>
              <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                  <div className="box-payment-method">
                    <div className="d-flex flex-column text-lightgray-iux ">
                      <div>
                        {props.account?.platform}&nbsp;
                        {props.account?.account_number}
                        &nbsp; ({props.account?.account_group?.display})
                      </div>
                      <div>
                        {props.account?.account_type === "Cent" ? "¢" : "$"}
                        {props.account?.current_balance
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <div className="box-payment-method">
                    <div className="d-flex align-items-center text-lightgray-iux ">
                      <img
                        src={`${
                          props.paymentSelected?.name === "DuitNow QR"
                            ? `${process.env.PUBLIC_URL}/assets/images/bank/${props.paymentSelected?.logo}`
                            : `${process.env.REACT_APP_POLICY}${props.paymentSelected?.logo} `
                        }`}
                        // src={`${process.env.REACT_APP_POLICY}${props.paymentSelected?.logo}`}
                        // src={`${process.env.PUBLIC_URL}/assets/images/bank/${props.paymentSelected?.logo}`}
                        alt="...."
                        width={40}
                        height={40}
                        style={{ borderRadius: 4 }}
                        onError={addDefaultSrc}
                      />
                      <div className="ml-2">
                        <div className="text-left w-100">
                          {props.paymentSelected?.name}
                        </div>
                        <div>{`(${locale.t(
                          "deposit_step.second_step.minimun_deposit"
                        )} ${props.paymentSelected?.payment_config[0]?.minimum_deposit
                          ?.toFixed(2)
                          ?.replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${
                          profileCountry?.symbol
                        })`}</div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Form
                layout={"vertical"}
                name="deposit_amount"
                onFinish={props.onNextStepPayment}
                className="user mx-auto"
                form={form}
                initialValues={{
                  amount: props.amount,
                }}
                // disabled={true}
              >
                <Row gutter={[16, 16]} className="mt-3">
                  <Col xs={24} md={12}>
                    <div>
                      <Form.Item
                        style={{ borderTop: "0" }}
                        name="amount"
                        label={locale.t("deposit_step.second_step.description")}
                        rules={[{ validator: checkDeposit, required: true }]}
                        className="payment"
                      >
                        <Input
                          // prefix={returnPrefixSuffix(true)}
                          // suffix={returnPrefixSuffix()}
                          prefix={
                            CheckCerrency(props?.user?.profile?.country_id)
                              ?.symbol
                          }
                          suffix={
                            CheckCerrency(props?.user?.profile?.country_id)?.iso
                          }
                          type="text"
                          // onKeyPress={(event) => {
                          //   if (!/^\d{0,2}(\.\d{0,2})?$/.test(event.key)) {
                          //     event.preventDefault();
                          //   }
                          // }}
                          onKeyPress={handleKeyPress}
                          onChange={(event) => {
                            form.setFieldsValue({
                              amount: removeLeadingZeros(event.target.value),
                            });
                            setAmount(removeLeadingZeros(event.target.value));
                            props.onChangeAmount(event.target.value);
                          }}
                        />
                      </Form.Item>{" "}
                      <div
                        className={`${
                          props.amount / rate < accountDetail?.minimum_deposit
                            ? "text-danger"
                            : "text-primary-sub-iux"
                        }`}
                      >
                        <Badge
                          key={"amount_more_than_1"}
                          color={`${
                            props.amount / rate < accountDetail?.minimum_deposit
                              ? "red"
                              : "#1CB894"
                          }`}
                        />
                        {locale.t("deposit_step.second_step.amount_more_than")}
                        {/* {account === "Raw" || account === "PRO" ? "1000" : "10"} USD */}
                        {accountDetail?.minimum_deposit} USD
                      </div>
                      {account !== "Raw" && account !== "Pro" && (
                        <div
                          className={`${
                            props.amount < minimum_deposit
                              ? "text-danger"
                              : "text-primary-sub-iux"
                          }`}
                        >
                          <Badge
                            key={"amount_more_than_2"}
                            color={`${
                              props.amount < minimum_deposit ? "red" : "#1CB894"
                            }`}
                          />
                          {locale.t(
                            "deposit_step.second_step.amount_more_than"
                          )}{" "}
                          {fCurrencySymbol(minimum_deposit)}&nbsp;
                          {CheckCerrency(props?.user?.profile?.country_id)?.iso}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <div className="w-12 text-lightgray-iux mb-2">
                      {locale.t("deposit_step.second_step.you_receive")}
                    </div>
                    <div className="box-payment-receive d-flex flex-column justify-content-between">
                      <div>
                        <div className="mb-3 text-lightgray-iux d-flex align-items-center justify-content-between">
                          <div>
                            {locale.t("deposit_info.currency_rate")}&nbsp;{" "}
                            <Tooltip
                              placement="topLeft"
                              title={
                                <div className="fs-10">
                                  {locale.t("faq.notice")}
                                  {/* notice
                                  * Please note that your deposit will depend on
                                  the exchange rate of the intermediary you
                                  use.When making a deposit, please ensure that
                                  the exchange rate is optimal for each deposit. */}
                                </div>
                              }
                            >
                              <InfoCircleOutlined className="text-lightgray fs-16" />
                            </Tooltip>
                          </div>
                          <span>
                            {fCurrencySymbol(rate.toFixed(2))}&nbsp;
                            {profileCountry?.symbol}
                          </span>
                        </div>
                        <div className="mb-3 text-lightgray-iux d-flex justify-content-between">
                          {locale.t("deposit_step.second_step.fee")}
                          <span>0.00 USD</span>
                        </div>
                      </div>
                    </div>{" "}
                    <div className="box-payment-resultamount d-flex justify-content-between">
                      <div>
                        {locale.t("deposit_step.second_step.description")}
                      </div>
                      <div>
                        {returnResultAmount()}{" "}
                        {props.account.account_type === "Cent" ? "USC" : "USD"}
                      </div>
                    </div>
                  </Col>
                </Row>

                <div className="d-flex justify-content-between mt-3">
                  <Button
                    className="btn-outline-iux  mr-1 button-default-w "
                    onClick={props.onBackStep}
                  >
                    {locale.t("back")}
                  </Button>

                  <Form.Item noStyle shouldUpdate>
                    {({ getFieldsValue, getFieldsError }) => {
                      return (
                        <Button
                          className="btn-primary-new-iux  ml-1 button-default-w "
                          // onClick={props.onNextStepPayment}
                          disabled={
                            amount === 0 ||
                            getFieldsError().filter(
                              ({ errors }) => errors.length
                            ).length > 0
                          }
                          htmlType="submit"
                        >
                          {locale.t("next")}
                        </Button>
                      );
                    }}
                  </Form.Item>
                </div>
              </Form>
            </>
          )}
        </>
      )}
    </div>
  );
}

const mapStateToProps = ({ user, i18n }) => {
  const { bookBankVerify } = user;
  return { bookBankVerify, user };
};

export default connect(mapStateToProps, null)(DepositThirdStep);
