import React, { useState, Fragment, useEffect } from "react";
import {
  Divider,
  Button,
  Form,
  Input,
  Row,
  Spin,
  Result,
  Select,
  Tooltip,
  Collapse,
} from "antd";
import { BarLoader } from "react-spinners";
import { post } from "../../../helper/request";
import { connect, useSelector } from "react-redux";
import DepositFirstStep from "./deposit/DepositFirstStep";
import DepositSecondStep from "./deposit/DepositSecondStep";
import DepositThirdStep from "./deposit/DepositThirdStep";
import { Link, useHistory } from "react-router-dom";
import OpenAccount from "../../../components/open-account/OpenAccount";
import styled from "styled-components";
import { CheckCerrency } from "../../../components/checkcurrency";
import BoxFAQ from "../../../components/boxfaq/BoxFAQ";
import BoxSelectPayment from "../../../components/boxpayment/BoxSelectPayment";
import { Base64Encoder } from "../../../helper/Base64Encoder";
import { renderApiDomain } from "../../../helper/renderApiDomain";
const locale = require("react-redux-i18n").I18n;
const { Option } = Select;
const { Panel } = Collapse;

const customPanelStyle = {
  background: "#FFF",
  borderRadius: 4,
  fontSize: 12,
  // marginBottom: 24,
  border: 0,
  overflow: "hidden",
};

export const Deposit = (props) => {
  const { user } = props;
  const history = useHistory();
  // const {
  //   paymentId,
  //   paymentKey,
  //   account: { account_number },
  //   payment_config: { minimum_deposit, maximum_deposit },
  //   payment_name,
  // } = props;
  const profileCountry = useSelector((state) => state.user.profileCountry);
  const payment = useSelector(({ user }) => user.bank);
  const [message, setMessage] = useState({ title: "", description: "" });
  // const [amount, setAmount] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [account, setAccount] = useState(props.account);
  const [paymentId, setPaymentId] = useState(2);
  const [paymentKey, setPaymentKey] = useState("");
  const [payment_config, setPayment_config] = useState({
    minimum_deposit: 100,
    maximum_deposit: 1000000,
    minimum_withdraw: 500,
    maximum_withdraw: 1000000,
  });
  let { minimum_deposit, maximum_deposit } = payment_config;
  const [payment_name, setPayment_name] = useState("");

  const [payment_id, setPayment_id] = useState(1);
  const [payment_key, setPayment_key] = useState(null);
  const [paymentSelected, setPaymentSelected] = useState(null);
  const [amount, setAmount] = useState(0);
  const [accountSelected, setAccountSelected] = useState(undefined);
  const [paymentDefault, setPaymentDefault] = useState(1);
  const [countryId, setCountryId] = useState(null);
  const [rate, setRate] = useState(0);
  const [alphaSymbol, setAlphaSymbol] = useState([]);
  const [step, setStep] = useState(null);
  const [suffix, setSuffix] = useState("");
  const [prefix, setPrefix] = useState("");
  const [loading, setLoading] = useState(false);

  const renderDefaultAccount = async () => {
    let urlParams = new URLSearchParams(window.location.search);
    let account_number = urlParams?.get("account");
    // props.user.accounts.find((e) => e.id === value);
    if (props.user?.accounts?.filter((e) => e.type === "real")?.length > 0) {
      if (account_number) {
        if (
          props.user?.accounts?.find(
            (e) => e.account_number === account_number && e.type === "real"
          )
        ) {
          await setAccountSelected(
            props.user?.accounts?.find(
              (e) => e.account_number === account_number
            )
          );
        } else {
          history.push(
            `/${localStorage.getItem("locale")}/wallet/fund/deposit`
          );
          setAccountSelected(null);
        }

        setStep(0);
      } else {
        setStep(0);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    renderDefaultAccount();
    setLoading(false);
  }, [props?.user?.accounts]);
  useEffect(() => {
    setLoading(true);

    if (paymentKey === "alphapo") {
      post(`${renderApiDomain()}deposit/alphapo/pairs`, {
        currency_from: "",
        type: "deposit",
      })
        .then(({ data: { data } }) => {
          setAlphaSymbol(data);
          setRate(parseFloat(data[0].rate_to));
          setSuffix(data[0]?.currency_to.currency);
          setPrefix(data[0]?.currency_from.currency);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setSuffix(profileCountry?.symbol);
      setPrefix("USD");
    }
    setLoading(false);
  }, []);
  useEffect(() => {
    setLoading(true);

    setCountryId(props?.user?.profile?.country_id);
    if (props.user.bank) {
      const defaultPayment = payment?.find((e) => e.recommend === 1);
      if (payment?.find((e) => e.recommend === 1)) {
        setPayment_key(defaultPayment?.payment_key);
        setPaymentDefault(defaultPayment?.payment_key);
        setPaymentSelected(props.user.bank.find((e) => e.recommend === 1));
      } else {
        if (props.user.bank.length > 0) {
          setPayment_key(props.user.bank[0]?.payment_key);
          setPaymentDefault(props.user.bank[0]?.payment_key);
          setPaymentSelected(props.user.bank[0]);
        } else {
          setPayment_key(null);
          setPaymentDefault(null);
          setPaymentSelected(null);
        }
      }
    } else {
      setPayment_key(null);
      setPaymentDefault(null);
      setPaymentSelected(null);
    }

    setLoading(false);
  }, [props?.user?.profile?.country_id]);

  const onBackStep = (isError) => {
    setAmount(0);
    console.log(isError);
    if (isError === "error") {
      setStep(0);
    } else {
      if (step === 1) {
        setAccountSelected(null);
        setPayment_key(null);
        setPaymentDefault(null);
        setPaymentSelected(null);
      }
      setStep(step - 1);
    }
  };
  const onsetRate = (value) => {
    setRate(value);
  };
  const onSuccess = (message) => {
    setShowSuccess(true);
    setMessage(message.message);

    setStep(step + 1);
  };
  const onNextStep = () => {
    setStep(step + 1);
  };
  const onNextStepPayment = (state) => {
    setStep(step + 1);
  };
  const onChangeAmount = (value) => {
    setAmount(value);
  };
  const onChangePayment = (value) => {
    if (props.user.bank.find((e) => e.payment_key === value)) {
      setPaymentSelected(props.user.bank.find((e) => e.payment_key === value));
    }
    setPayment_key(value);
  };

  const handleOk = () => {
    setStep(0);
  };

  const handleCancel = () => {
    setStep(0);
  };
  const onAccountSelected = (value) => {
    if (props.user.accounts.find((e) => e.id === value)) {
      setAccountSelected(props.user.accounts.find((e) => e.id === value));
    }
  };
  const renderStep = (step) => {
    switch (step) {
      case 0:
        return (
          <DepositFirstStep
            key={"deposit step 1"}
            locale={locale}
            accounts={props.accounts}
            onAccountSelected={onAccountSelected}
            onNextStep={onNextStep}
            accountSelected={accountSelected}
            onChangePayment={onChangePayment}
            paymentKey={payment_key}
            paymentDefault={paymentDefault}
            paymentSelected={paymentSelected}
          />
        );
      case 1:
        return (
          <Fragment>
            <DepositSecondStep
              key={"deposit step 2"}
              amount={amount}
              paymentId={paymentSelected.id}
              paymentKey={payment_key}
              payment_config={paymentSelected.payment_config[0]}
              payment_name={payment_name}
              account={accountSelected}
              onSuccess={onSuccess}
              onBackStep={onBackStep}
              onNextStepPayment={onNextStepPayment}
              onChangeAmount={onChangeAmount}
              onsetRate={onsetRate}
              paymentSelected={paymentSelected}
            />
          </Fragment>
        );
      case 2:
        return (
          <>
            <div
              style={{ borderRadius: 20 }}
              headStyle={{ textAlign: "center" }}
              title="Deposit"
              visible={step === 2}
              width={580}
              className="user"
            >
              <DepositThirdStep
                key={"deposit step 3"}
                amount={amount}
                paymentId={paymentSelected?.id}
                paymentKey={payment_key}
                payment_config={paymentSelected?.payment_config[0]}
                payment_name={payment_name}
                account={accountSelected}
                suffix={suffix}
                onSuccess={onSuccess}
                onBackStep={onBackStep}
                onNextStepPayment={onNextStepPayment}
                rate={rate}
                paymentSelected={paymentSelected}
              />
            </div>
          </>
        );
      default: {
        if (showSuccess) {
          return (
            <Result
              status="success"
              title={message.title}
              subTitle={message.description}
              extra={[
                <Link
                  to={`/${localStorage.getItem("locale")}/wallet`}
                  className="text-center"
                >
                  <Button className="btn-primary-new-iux  button-default-w">
                    {locale.t("deposit_info.done")}
                  </Button>
                </Link>,
              ]}
            />
          );
        }
      }
    }
  };

  return (
    <div className="px-2 pb-2 px-sm-4 pb-sm-2">
      {/* <a ref={refLinkPayment} href={linkPay} hidden>
        LinkPayment
      </a> */}
      {!loading ? (
        <div className="row ">
          <div className="col-12 col-lg-8 ">
            <div style={{ maxWidth: "100%" }}>
              {props.user.accounts.filter((e) => e.type === "real").length >
              0 ? (
                renderStep(step)
              ) : (
                <OpenAccount />
              )}
            </div>
          </div>
          <div className="d-none d-lg-block col-0 col-lg-4 user">
            <BoxFAQ step={step} type={"deposit"} />
          </div>
          {/* <div className="d-none d-sm-block col-0 col-md-4 user">
            <div style={{ maxWidth: 300 }}>
              {step === 0 ? (
                <>
                  <div className="d-flex justify-content-between ">
                    <div className="fw-700">{locale.t("faq.title_faq")}</div>
                    <div
                      className="text-primary-new-iux cursor-pointer"
                      onClick={() =>
                        window.open(
                          `https://help.iuxmarkets.com/${
                            localStorage.getItem("locale") === "ja"
                              ? "ja"
                              : localStorage.getItem("locale")
                          }/`
                        )
                      }
                    >
                      {locale.t("faq.viewmore")}
                    </div>
                  </div>
                  <Collapse
                    bordered={false}
                    className="user"
                    style={{ backgroundColor: "#FFF" }}
                    expandIconPosition="right"
                  >
                    <Panel
                      header={locale.t("faq.faq_title_1")}
                      key="1"
                      style={customPanelStyle}
                    >
                      <p>{locale.t("faq.faq_des_1")}</p>
                    </Panel>
                    <Panel
                      header={locale.t("faq.faq_title_2")}
                      key="2"
                      style={customPanelStyle}
                    >
                      <p>{locale.t("faq.faq_des_2")}</p>
                    </Panel>
                    <Panel
                      header={locale.t("faq.faq_title_3")}
                      key="3"
                      style={customPanelStyle}
                    >
                      <p>{locale.t("faq.faq_des_3")}</p>
                    </Panel>
                    <Panel
                      header={locale.t("faq.faq_title_4")}
                      key="4"
                      style={customPanelStyle}
                    >
                      <p>{locale.t("faq.faq_des_4")}</p>
                    </Panel>
                    {user?.bonus_config?.bonus_countries?.find(
                      (e) => e === props?.user?.profile?.country_id
                    ) && (
                      <Panel
                        header={locale.t("faq.faq_title_5")}
                        key="5 "
                        style={customPanelStyle}
                      >
                        <p>{locale.t("faq.faq_des_5")}</p>
                      </Panel>
                    )}
                  </Collapse>
                  <div className="fs-10 text-primary-sub-iux mt-3">
                    {locale.t("faq.faq_footer")}
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex justify-content-between">
                    <div className="fw-700">{locale.t("faq.notice_title")}</div>
                  </div>
                  <div style={{ marginTop: 24 }}>{locale.t("faq.notice")}</div>
                </>
              )}
            </div>
          </div> */}
        </div>
      ) : (
        <>
          {" "}
          <div className="text-center mb-4">
            <img
              alt="IUX"
              className="registerlogo border-logo"
              src={"/assets/images/icons/iux-logo.png"}
            />
          </div>
          <div>
            <BarLoader
              color="#00AF6C"
              height={4}
              width={200}
              css="border-radius: 4px;"
            />
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ user, i18n }) => {
  const { accounts, profile, account } = user;
  return { accounts, profile, account, locale: i18n.locale, user };
};

export default connect(mapStateToProps, null)(Deposit);
