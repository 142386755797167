import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Space,
  Table,
  Tag,
  Badge,
  Empty,
  Select,
  Button,
  ConfigProvider,
  DatePicker,
  Pagination,
  Row,
  Col,
  message,
  Spin,
} from "antd";
import { get, post } from "../../../helper/request";
import moment from "moment";
import { connect } from "react-redux";
import { ExportOutlined, UploadOutlined } from "@ant-design/icons";
import { CheckLanguageAntd } from "../../../helper/countryLangAntd";
import PaymentSlip from "../../../components/paymentslip/PaymentSlip";
import { renderApiDomain } from "../../../helper/renderApiDomain";

const locale = require("react-redux-i18n").I18n;
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

const dayjs = require("dayjs");

export const Transaction = (props) => {
  const [transactions, setTransactions] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [countryId, setCountryId] = useState(0);
  const [MontValue, setMontValue] = useState(null);
  const [datepickerOpen, setDatepickerOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [selectedMonths, setSelectedMonths] = useState([dayjs(), dayjs()]);
  const [isDate, setIsDate] = useState(
    `${moment().add(-29, "days").format("YYYY-MM-DD")},${moment().format(
      "YYYY-MM-DD"
    )}`
  );
  const [typeValue, setTypeValue] = useState("");
  const [statusValue, setStatusValue] = useState("");

  const renderprefix = () => {
    if (countryId === 8) {
      return "₭";
    } else if (countryId === 6) {
      return "RM";
    } else if (countryId === 10) {
      return "₫";
    } else if (countryId === 9) {
      return "Rp";
    } else {
      return "฿";
    }
  };

  const [messageApi, contextHolder] = message.useMessage();

  const [loading, setLoading] = useState(false);

  const exportHandler = (type) => {
    post(renderApiDomain() + `user/account/trade/transaction`, {
      type: type,
      start_date: moment(selectedMonths[0]?.$d)
        .startOf("month")
        .format("YYYY-MM-DD"),
      end_date: moment(selectedMonths[1]?.$d)
        .endOf("month")
        .format("YYYY-MM-DD"),
    })
      .then((res) => {
        // window.open(res.data.data.url, "_blank");
        messageApi.open({
          type: "success",
          content: "Send to email.",
        });
      })
      .catch((e) => {
        // console.log(e);
        messageApi.open({
          type: "error",
          content: "error",
        });
      });
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const columnsdata = [
    {
      title: (
        <div className="fs-12 text-gray">
          {locale.t("dashboardTransactionHistory.headers.type")}
        </div>
      ),
      dataIndex: "type",
      key: "type",

      render: (type) => {
        let color = "#1CB894";
        if (type === "withdraw") {
          color = "#F41112";
        }
        return (
          <span>
            <p style={{ color: color, width: "80px" }} key={type}>
              {type === "deposit"
                ? locale.t("dashboardTransactionHistory.type.Deposit")
                : locale.t("dashboardTransactionHistory.type.Withdraw")}
            </p>
          </span>
        );
      },

      filteredValue: filteredInfo.amount || null,
      onFilter: (value, record) => record.amount.includes(value),
      // sorter: (a, b) => a.amount - b.amount,
      sortOrder: sortedInfo.columnKey === "amount" && sortedInfo.order,
      width: "5%",
    },
    {
      title: (
        <div className="fs-14 text-gray">
          {locale.t("dashboardTransactionHistory.headers.time")}
        </div>
      ),
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date) => {
        return (
          <span>
            <p key={date} className="text-black">
              {moment(date).format("YYYY/MM/DD HH:mm")}
            </p>
          </span>
        );
      },
      onFilter: (value, record) => record.date.includes(value),
      // sorter: (a, b) => {
      //   return a.createdAt - b.createdAt;
      // },
      sortOrder: sortedInfo.columnKey === "createdAt" && sortedInfo.order,
      width: "22%",
    },
    {
      title: (
        <div className="fs-12 text-gray">
          {/* {locale.t("dashboardFund.tabHistory.account")} */}
          {locale.t("dashboardTransactionHistory.headers.account")}
        </div>
      ),
      dataIndex: "account_number",
      key: "account_number",
      render: (account_number) => {
        return <span className="text-black">{account_number}</span>;
      },
      onFilter: (value, record) => record.account.includes(value),
      // sorter: (a, b) => a.account_trade.length - b.account_trade.length,
      sortOrder: sortedInfo.columnKey === "account" && sortedInfo.order,
      width: "5%",
    },
    {
      title: (
        <div className="fs-12 text-gray">
          {locale.t("dashboardTransactionHistory.headers.method")}
        </div>
      ),
      dataIndex: "logo",
      key: "logo",
      render: (method, data) => {
        return (
          <div className="d-flex text-black">
            {data?.logo ? (
              <img
                src={`${process.env.REACT_APP_POLICY}${data?.logo}`}
                onError={addDefaultSrc}
                alt="method"
                // src={
                //   process.env.PUBLIC_URL +
                //   "/assets/images/bank/" +
                //   data?.payment?.logo
                // }
                width={20}
                height={20}
              />
            ) : (
              <img
                alt="method"
                src={`${process.env.REACT_APP_POLICY}${data?.logo}`}
                onError={addDefaultSrc}
                // src={process.env.PUBLIC_URL + "/assets/images/bank/" + method}
                width={20}
                height={20}
              />
            )}
            <span className="ml-2">{data?.payment_name}</span>
          </div>
        );
      },
      filteredValue: filteredInfo.method || null,
      onFilter: (value, record) => record.method.includes(value),
      // sorter: (a, b) => a.method - b.method,
      sortOrder: sortedInfo.columnKey === "method" && sortedInfo.order,
      width: "25%",
    },
    {
      title: (
        <div className="fs-12 text-gray">
          {locale.t("dashboardTransactionHistory.headers.localAmount")}
        </div>
      ),
      dataIndex: "amount_local",
      key: "amount_local",
      render: (amount, data) => {
        return (
          <span className="text-black">
            {data.payment_key !== "iuxtransfer" ? (
              <>
                {data.payment_key === "b2binpay" && (
                  <span>
                    {amount?.toFixed(6)}&nbsp;
                    {data.currency}
                  </span>
                )}

                {data.payment_key !== "b2binpay" && (
                  <>
                    {data?.payment_name === "IUX Markets Tranfer"
                      ? "$"
                      : renderprefix()}
                    {amount?.toFixed(2)?.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                  </>
                )}
              </>
            ) : (
              <>-</>
            )}
          </span>
        );
      },
      filteredValue: filteredInfo.amount || null,
      onFilter: (value, record) => record.amount.includes(value),
      // sorter: (a, b) => a.amount - b.amount,
      sortOrder: sortedInfo.columnKey === "amount" && sortedInfo.order,
      width: "17%",
    },

    {
      title: (
        <div className="fs-12 text-gray">
          {locale.t("dashboardTransactionHistory.headers.receive")}
        </div>
      ),
      dataIndex: "amount",
      key: "amount",
      render: (amount, data) => {
        let color = "#1CB894";
        if (amount < 0) {
          color = "#F41112";
        }
        return (
          <span>
            <p style={{ color: color }} key={amount}>
              $
              {Math.abs(amount || data.credit)
                ?.toFixed(2)
                ?.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
            </p>
          </span>
        );
      },
      filteredValue: filteredInfo.amount || null,
      onFilter: (value, record) => record.amount.includes(value),
      // sorter: (a, b) => a.amount - b.amount,
      sortOrder: sortedInfo.columnKey === "amount" && sortedInfo.order,
      width: "15%",
    },

    {
      title: (
        <div className="fs-12 text-gray">
          {/* {locale.t("dashboardFund.tabHistory.status")} */}
          {locale.t("dashboardTransactionHistory.headers.status")}
        </div>
      ),
      dataIndex: "status",
      key: "status",
      render: (status) => {
        const colorIcon = (type) => {
          if (type === "Accepted") {
            return "#1CB894";
          }
          if (type === "Pending") {
            return "#FED544";
          }
          if (type === "Rejected") {
            return "#E3673F";
          }
        };
        return (
          <span
            className="fs-14 d-flex align-items-center"
            style={{ width: "130px" }}
          >
            <div
              style={{
                width: "6px",
                height: "6px",
                background: colorIcon(capitalizeFirstLetter(status)),
                borderRadius: "50%",
                marginRight: "8px",
              }}
            />{" "}
            {status &&
              locale.t(
                "dashboardTransactionHistory.status." +
                  capitalizeFirstLetter(status)
              )}
          </span>
        );
      },
      onFilter: (value, record) => record.status.includes(value),
      // sorter: (a, b) => a.status.length - b.status.length,
      sortOrder: sortedInfo.columnKey === "status" && sortedInfo.order,
      width: "8%",
    },
  ];

  const addDefaultSrc = (ev) => {
    ev.target.src = "/assets/images/icons/globe-black.svg";
  };

  const fetchTranactions = () => {
    setLoading(true);
    if (
      selectedMonths?.length === 0 ||
      !selectedMonths ||
      selectedMonths[0] === null ||
      selectedMonths[1] === null
    ) {
      setTotal(0);
      setTransactions([]);
      setLoading(false);
      return;
    }
    const dateMonth =
      moment(selectedMonths[0]?.$d).startOf("month").format("YYYY-MM-DD") +
      "," +
      moment(selectedMonths[1]?.$d).endOf("month").format("YYYY-MM-DD");
    get(
      renderApiDomain() +
        // `v2/user/account/trade/transaction?limit=${10}&page=${page}&status=${statusValue}&type=${typeValue}&date=2020-10-10,2023-11-08`
        `user/account/trade/transaction?limit=${10}&page=${page}&status=${statusValue}&type=${typeValue}&date=${dateMonth}`
    )
      .then((res) => {
        const datakey = res.data.data.map((e, i) => (e.key = i + 1));
        setTransactions(res.data.data);
        setTotal(res.data.total);
        setLoading(false);
        // setColumns(columnsdata);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    setCountryId(props?.user?.profile?.country_id);
    fetchTranactions();
  }, [
    props?.user?.profile?.country_id,
    typeValue,
    statusValue,
    page,
    selectedMonths,
  ]);

  useEffect(() => {
    setPage(1);
  }, [
    props?.user?.profile?.country_id,
    typeValue,
    statusValue,
    isDate,
    selectedMonths,
  ]);

  const onChangeCustomDate = (value) => {
    setMontValue(value);
    let currentDate = moment(value.$d).startOf("month").format("YYYY-MM-DD");
    setIsDate(
      `${currentDate},${moment(currentDate)
        .endOf("month")
        .format("YYYY-MM-DD")}`
    );
    setDatepickerOpen(false);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [slipData, setSlipData] = useState({});

  const openModalhandler = (data) => {
    setSlipData({
      ...data,
      rate: data.usd_rate,
      type: data.type,
      requestedDate: data.updatedAt,
      bookbank: {
        bank_account_name: data.account_name,
        bank_account_number: data.transfer_account_number,
        bank_name: data.bank_name,
      },
      amount: data.amount,
      account: data.account_name,
      localamount: Math.abs(data.usd_rate) * Math.abs(data.amount),
    });
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const renderExpand = (data) => {
    return (
      <div className="overflow-hidden transaction-history p-0">
        <Row>
          <Col span={12}>
            <div className="fs-12 font-weight-bold ml-3 ml-lg-0">
              {data?.wallet_address
                ? locale.t("dashboardWallet.detail")
                : locale.t("dashboardTransactionHistory.expand.otherDetails")}
            </div>
          </Col>
          {data.type === "withdraw" && (
            <Col span={12}>
              <div
                className="fs-12 font-weight-bold"
                style={{ marginLeft: "15px" }}
              >
                {data.wallet_address
                  ? ""
                  : locale.t(
                      "dashboardTransactionHistory.expand.bankAccountDetail"
                    )}
              </div>
            </Col>
          )}
        </Row>

        <div className="row mt-2">
          <div className="col-2">
            <div className="fs-12 text-gray">
              {locale.t("dashboardTransactionHistory.expand.accountType")}
            </div>
            <div className="fs-12">{data?.account_type}</div>
          </div>
          {data.payment_key === "iuxtransfer" && (
            <div className="col-2">
              <div className="fs-12 text-gray">Account Number</div>
              <div className="fs-12">{data?.account_number}</div>
            </div>
          )}
          <div className="col-2">
            <div className="fs-12 text-gray">
              {locale.t("dashboardTransactionHistory.expand.transactionID")}
            </div>
            <div className="fs-12">{data.id}</div>
          </div>
          {data.payment_key === "iuxtransfer" && (
            <div className="col-2">
              <div className="fs-12 text-gray">Receive Type</div>
              <div className="fs-12">{data?.affiliate_transfer_type}</div>
            </div>
          )}
          {data.payment_key !== "iuxtransfer" && (
            <div className="col-2">
              <div className="fs-12 text-gray">
                {locale.t("dashboardTransactionHistory.expand.currencyRate")}
              </div>
              {data.payment_key === "b2binpay" && (
                <div className="fs-12">
                  {Math.abs(data.usd_rate?.toFixed(6))} USD/
                  {data?.currency}
                </div>
              )}
              {data.payment_key !== "b2binpay" && (
                <div className="fs-12">
                  {Math.abs(data.usd_rate?.toFixed(2))} USD/
                  {data?.currency}
                </div>
              )}
            </div>
          )}
          {data.payment_key === "b2binpay" && (
            <div className="col-2">
              <div className="fs-12 text-gray">
                {locale.t("withdraw_info.bockchain_fee")}
              </div>
              <div>
                {data.blockchain_fee || 0}&nbsp;
                {data.currency}
              </div>
              <div>
                ≈ {(data.blockchain_fee / data.usd_rate || 0).toFixed(6) || 0}{" "}
                USD
              </div>
            </div>
          )}
          {data.type === "withdraw" && (
            <>
              {data.wallet_address ? (
                <>
                  {" "}
                  <div className="col-4">
                    {data.payment_key === "b2binpay" && (
                      <div className="fs-12 text-gray">Wallet Address</div>
                    )}
                    {data.payment_key !== "b2binpay" && (
                      <div className="fs-12 text-gray">
                        {data.payment_name} Account
                      </div>
                    )}
                    <div className="fs-12">{data.wallet_address}</div>
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  <div className="col-2">
                    <div className="fs-12 text-gray">
                      {locale.t(
                        "dashboardTransactionHistory.expand.accountNumber"
                      )}
                    </div>
                    <div className="fs-12">{data.transfer_account_number}</div>
                  </div>
                  <div className="col-2">
                    <div className="fs-12 text-gray">
                      {locale.t(
                        "dashboardTransactionHistory.expand.accountName"
                      )}
                    </div>
                    <div className="fs-12">{data.account_name}</div>
                  </div>
                  <div className="col-2">
                    <div className="fs-12 text-gray">
                      {locale.t("dashboardTransactionHistory.expand.bankName")}
                    </div>
                    <div className="fs-12">{data.bank_name}</div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
        {data?.status?.toLowerCase() === "accepted" && (
          <div className="export-data">
            {locale.t("dashboardTransactionHistory.expand.proofOfTransfer")}{" "}
            <span
              className="export-button ml-1"
              onClick={() => openModalhandler(data)}
            >
              {locale.t("dashboardTransactionHistory.expand.exportHere")}{" "}
              <ExportOutlined className="ml-1" />
            </span>
          </div>
        )}
      </div>
    );
  };
  // filteredInfo: null,
  // sortedInfo: null,
  // search: "",

  const disabledDate = (current) => {
    // if (selectedMonths?.length === 0 || !selectedMonths) {

    if (selectedMonths?.length === 0 || !selectedMonths) {
      return false; // No dates are selected yet, so allow any date.
    }

    if (selectedMonths[0] === null && selectedMonths[1] === null) {
      return false; // No dates are selected yet, so allow any date.
    }

    if (selectedMonths[0] !== null && selectedMonths[1] === null) {
      const threeMonthsAgo = selectedMonths[0]?.clone().subtract(2, "months");
      const threeMonthsFromNow = selectedMonths[0]?.clone().add(2, "months");

      return (
        current.isBefore(threeMonthsAgo, "month") ||
        current.isAfter(threeMonthsFromNow, "month")
      );
    }

    const threeMonthsAgo = selectedMonths[1]?.clone().subtract(2, "months");
    const threeMonthsFromNow = selectedMonths[0]?.clone().add(2, "months");

    return (
      current.isBefore(threeMonthsAgo, "month") ||
      current.isAfter(threeMonthsFromNow, "month")
    );
  };

  const handleCalendarChange = (dates) => {
    setSelectedMonths(dates);
  };

  return (
    <>
      <div className="user transaction-history">
        <div className="space">
          <Row
            gutter={[12, 12]}
            className="d-flex justify-content-end justify-content-lg-start"
          >
            <Col
              xs={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
              className="d-flex flex-column cursor-pointer"
            >
              <div className="title-history">
                {locale.t("dashboardTransactionHistory.filter.month")}
              </div>
              {/* <Select
              defaultValue={1}
              style={{ width: 260, pointerEvents: "none" }}
              options={[{ value: 1, label: isDate }]}
            /> */}
              <ConfigProvider
                locale={CheckLanguageAntd(localStorage.getItem("locale"))}
              >
                <RangePicker
                  picker="month"
                  value={selectedMonths}
                  onCalendarChange={handleCalendarChange}
                  disabledDate={disabledDate}
                  onClick={() => setSelectedMonths([null, null])}
                  allowClear={false}
                  inputReadOnly
                />
              </ConfigProvider>
            </Col>
            <Col
              className="d-flex flex-column"
              xs={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 4 }}
            >
              <div className="title-history">
                {" "}
                {locale.t("dashboardTransactionHistory.filter.type")}
              </div>
              <Select
                defaultValue={typeValue}
                onChange={(e) => setTypeValue(e)}
                options={[
                  {
                    value: "",
                    label: locale.t("dashboardTransactionHistory.filter.all"),
                  },
                  {
                    value: "deposit",
                    label: locale.t("dashboardTransactionHistory.type.Deposit"),
                  },
                  {
                    value: "withdraw",
                    label: locale.t(
                      "dashboardTransactionHistory.type.Withdraw"
                    ),
                  },
                ]}
              />
            </Col>
            <Col
              className="d-flex flex-column"
              xs={{ span: 24 }}
              md={{ span: 6 }}
              lg={{ span: 4 }}
            >
              <div className="title-history">
                {" "}
                {locale.t("dashboardTransactionHistory.filter.status")}
              </div>
              <Select
                defaultValue={statusValue}
                onChange={(e) => setStatusValue(e)}
                options={[
                  {
                    value: "",
                    label: locale.t("dashboardTransactionHistory.filter.all"),
                  },
                  {
                    value: "accepted",
                    label: locale.t(
                      "dashboardTransactionHistory.status.Accepted"
                    ),
                  },
                  {
                    value: "pending",
                    label: locale.t(
                      "dashboardTransactionHistory.status.Pending"
                    ),
                  },
                  {
                    value: "rejected",
                    label: locale.t(
                      "dashboardTransactionHistory.status.Rejected"
                    ),
                  },
                ]}
              />
            </Col>
            {total > 0 &&
              statusValue !== "pending" &&
              statusValue !== "rejected" && (
                <Col>
                  <div
                    className="fs-12 fw-300"
                    style={{ visibility: "hidden" }}
                  >
                    Hidden
                  </div>
                  <div>
                    <div className="d-flex justify-content-end justify-content-md-start  ">
                      <Button
                        className="btn-outline-export w-100 w-xs-auto"
                        style={{ height: "48px" }}
                        onClick={() => exportHandler(typeValue)}
                      >
                        <UploadOutlined />
                        {locale.t("export")}
                      </Button>
                    </div>
                  </div>
                </Col>
              )}
          </Row>
          <ConfigProvider
            locale={CheckLanguageAntd(localStorage.getItem("locale"))}
          >
            {datepickerOpen && (
              <div
                className="report-datepicker-backdrop"
                onClick={() => setDatepickerOpen(false)}
              />
            )}
            <div className="custom-calendar">
              <MonthPicker
                className="report-datepicker"
                value={MontValue}
                // disabledDate={disabledDate}
                // className="w-100"
                open={datepickerOpen}
                onChange={onChangeCustomDate}
                placeholder="Select month"
              />
            </div>
          </ConfigProvider>
        </div>
        {/* <h5 className="mb-3">{locale.t("dashboardFund.tabHistory.title")}</h5> */}
        {/* <Table
        locale={{
          emptyText: (
            <Empty
              description={locale.t("nodata")}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          ),
        }}
        columns={columnsdata}
        expandIconColumnIndex={7}
        // expandIcon={() => }
        expandable={{
          expandRowByClick: true,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <img
                src="/assets/images/icons/dashboard/arrow-up.svg"
                type="up"
                alt="arrow-up"
                style={{ fontSize: "20px" }}
                className="cursor-pointer"
                onClick={(e) => onExpand(record, e)}
              />
            ) : (
              <img
                src="/assets/images/icons/dashboard/arrow-down.svg"
                type="down"
                alt="arrow-down"
                style={{ fontSize: "20px" }}
                className="cursor-pointer"
                onClick={(e) => onExpand(record, e)}
              />
            ),

          expandedRowRender: (record) => renderExpand(record),
          rowExpandable: (record) => record.account_trade !== "",
        }}
        pagination={{
          showSizeChanger: false,
        }}
        // dataSource={dataCalendar}.
        dataSource={transactions.filter(
          (transaction) =>
            transaction.status === "pending" &&
            moment(transaction.createdAt).isSame(moment(), "day")
        )}
      /> */}
        {/* <h5 className="mb-3">
        {locale.t("dashboardFund.tabHistory.title2")} */}
        {/* <RangePicker
                  placeholder={['จากวันที่', 'ถึงวันที่']} className="ml-2" /> */}
        {/* </h5> */}
        {!loading ? (
          <Table
            locale={{
              emptyText: (
                <Empty
                  description={locale.t("nodata")}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              ),
            }}
            columns={columnsdata}
            expandIconColumnIndex={7}
            // expandIcon={() => }
            expandable={{
              expandRowByClick: true,
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <img
                    src="/assets/images/icons/dashboard/arrow-up.svg"
                    type="up"
                    alt="arrow-up"
                    style={{ fontSize: "20px" }}
                    className="cursor-pointer"
                    onClick={(e) => onExpand(record, e)}
                  />
                ) : (
                  <img
                    src="/assets/images/icons/dashboard/arrow-down.svg"
                    type="down"
                    alt="arrow-down"
                    style={{ fontSize: "20px" }}
                    className="cursor-pointer"
                    onClick={(e) => onExpand(record, e)}
                  />
                ),

              expandedRowRender: (record) => renderExpand(record),
              rowExpandable: (record) => record.account_trade !== "",
            }}
            pagination={false}
            // dataSource={dataCalendar}.
            dataSource={transactions}
          />
        ) : (
          <div style={{ textAlign: "center" }}>
            <Spin />
          </div>
        )}
        {total > 0 && (
          <div style={{ marginTop: "18px" }}>
            <Pagination
              defaultCurrent={1}
              current={page}
              onChange={(page) => setPage(page)}
              total={total}
              pageSize={10}
              style={{ textAlign: "end" }}
              showTotal={false}
              showSizeChanger={false}
            />
          </div>
        )}
      </div>
      <Modal open={isModalOpen} footer={null} onCancel={handleClose}>
        <div className="p-0 p-sm-3">
          <PaymentSlip type="deposit" onClose={handleClose} {...slipData} />
        </div>
      </Modal>
      {contextHolder}
    </>
  );
};

const mapStateToProps = ({ i18n, user }) => {
  return { locale: i18n.locale, user };
};

export default connect(mapStateToProps, null)(Transaction);
