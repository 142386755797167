import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Layout,
  Button,
  Modal,
  Input,
  Select,
  Form,
  message,
  Alert,
  Popover,
  Divider,
  Progress,
} from "antd";
import { StyledAccountWallet, SubStyledAccountWallet } from "../../components";
import mybonus from "../../mock/mybonus.json";
import RealAccountCardx from "../../components/account-card/StandardXCard";
import { getAccount } from "../../actions";
import { post, get, patch } from "../../helper/request";
import RcQueueAnim from "rc-queue-anim";
import {
  addAccount,
  updateLaverage,
  deleteAccount,
  archiveAccount,
  getArchive,
} from "../../actions";
import { connect, useSelector } from "react-redux";
import VerifyUser from "./fund/verifies/VerifyUser";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useParams, Link, useHistory } from "react-router-dom";
import { renderApiDomain } from "../../helper/renderApiDomain";
const BoxBorder = styled.div`
  display: flex;
  border-radius: 2px;
  padding: 9px;
  border: 1px solid ${(props) => (props.active ? "#009EFF" : "gray")};
  background: ${(props) => (props.active ? "#009eff38" : "#fff")};
  width: 100%;
  cursor: pointer;
`;

const RadioOutside = styled.div`
  background: white;
  border-radius: 20px;
  border: 1px solid ${(props) => (props.active ? "#009EFF" : "gray")};
  padding: 3px;
`;

const RadioInside = styled.div`
  padding: 6px;
  background: ${(props) => (props.active ? "#009EFF" : "#fff")};
  border-radius: 50%;
`;

const locale = require("react-redux-i18n").I18n;
const { confirm } = Modal;
const { Option } = Select;
const refAdd = React.createRef();
const refAddMobile = React.createRef();
const refEdit = React.createRef();
const refEditInvestor = React.createRef();
const refLeverage = React.createRef();
const styleInput = {
  // height: 42,
};
const Accounts = (props) => {
  const { type } = useParams();
  const history = useHistory();
  const accountTypeList = useSelector((state) => state.account.accountTypeList);
  const isDetail =
    accountTypeList && accountTypeList.find((e) => e.type === "welcome");
  const [dataAccount, setdataAccount] = useState(null);
  const dispatch = useDispatch();
  const isPlatform = props.selectPlatform.toUpperCase();
  const [form] = Form.useForm();
  const [accounts, setAccounts] = useState(props.user.accounts);
  const [accountSelected, setAccountSelected] = useState(null);
  const [currency, setCurrency] = useState("USD");
  const [modalCreateVisible, setModalCreateVisible] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [hoverInformation, setHoverInformation] = useState(false);
  const [modalEditPassword, setModalEditPassword] = useState(null);
  const [modalEditInvestorPassword, setModalEditInvestorPassword] =
    useState(null);
  const [modalWithdraw, setModalWithdraw] = useState(null);
  const [modalInfoBonus, setModalInfoBonus] = useState(null);
  const [dataCondition, setDataCondition] = useState(null);
  const [dataChallenge, setDataChallenge] = useState(null);
  const [dataProfileBonus, setDataProfileBonus] = useState(null);
  const [withdrawSelected, setWithdrawSelected] = useState(null);
  const user = useSelector((state) => state.user);
  const [modalEditLeverage, setModalEditLeverage] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [active, setActive] = useState({ mt4: false, mt5: false, key: "MT4" });
  const [modalAlertConditon, setModalAlertConditon] = useState(false);
  const [bonus, setBonus] = useState([
    props.user.bonus_balance_30,
    props.user.bonus_balance_15,
  ]);
  const [countDown, setCountDown] = useState(0);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    if (accounts !== props.user.accounts) setAccounts(props.user.accounts);
    if (
      props.user.bonus_balance_30 !== bonus[0] ||
      props.user.bonus_balance_15 !== bonus[1]
    ) {
      setBonus([props.user.bonus_balance_30, props.user.bonus_balance_15]);
    }
    return;
  });

  useEffect(() => {
    fetchChallenge();
    fetchCondition();
    fetchBonusProfile();
    setActive({ mt4: true, mt5: false, key: "MT4" });
  }, []);
  useEffect(() => {
    const myInterval = setInterval(() => {
      if (countDown > 0) {
        setCountDown(countDown - 1);
      }
      if (countDown === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
          // setLoading(false);
        } else {
          setMinutes(minutes - 1);
          setCountDown(5);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [countDown]);
  const fetchChallenge = async () => {
    try {
      const { data } = await get(renderApiDomain() + "bonus/challenge/user");

      setDataChallenge(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const patchChallenge = async (second) => {
    try {
      const { data } = await patch(
        renderApiDomain() + "bonus/challenge/user/sync"
      );
      setCountDown(5);
      setDataChallenge(data.data);
      message.success(locale.t("bonus_update"), 2.5);
    } catch (error) {
      message.error("something went wrong", {
        duration: 2.5,
      });
      console.log(error);
    }
  };
  const fetchCondition = async () => {
    try {
      const { data } = await get(
        renderApiDomain() + "bonus/withdraw-condition"
      );
      setDataCondition(data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchBonusProfile = async () => {
    try {
      const { data } = await get(renderApiDomain() + "bonus/profile");
      setDataProfileBonus(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const onEdit = async () => {
    try {
      setButtonLoading(true);
      const values = await form.validateFields();
      post(renderApiDomain() + "user/account/password/change", {
        account_trade_id: modalEditPassword.id,
        newPassword: values.new_password,
        reset_code: values.reset_code,
      })
        .then((res) => {
          refEdit.current.resetFields();
          setModalEditPassword(null);
          // window.location.reload();
          message.success("Update success!");
          window.location.reload();
        })
        .catch((e) => {
          message.error(e.response.data.error);
          setButtonLoading(false);
        });
    } catch (error) {
      console.log(error);
      setButtonLoading(false);
    }
  };

  const onEditInvestor = async () => {
    try {
      setButtonLoading(true);
      const values = await form.validateFields();
      post(renderApiDomain() + "user/account/password-investor/change", {
        account_trade_id: modalEditInvestorPassword.id,
        newPassword: values.new_password_investor,
        reset_code_investor: values.reset_code_investor,
      })
        .then((res) => {
          refEditInvestor.current.resetFields();
          setModalEditPassword(null);
          message.success("Update success!");
          window.location.reload();
        })
        .catch((e) => {
          message.error(e.response.data.error);
          setButtonLoading(false);
        });
    } catch (error) {
      message.error(error.response.data.error);
      setButtonLoading(false);
    }
  };

  const onEditLeverage = async () => {
    try {
      setButtonLoading(true);
      const values = await form.validateFields();
      post(renderApiDomain() + "user/account/leverage/change", {
        account_trade_id: modalEditLeverage.id,
        leverage: values.edit_leverage,
      })
        .then((res) => {
          refLeverage.current.resetFields();
          setModalEditLeverage(null);
          message.success("Update success!");
          dispatch(getAccount());
          setButtonLoading(false);
        })
        .catch((e) => {
          try {
            message.error(e.response?.data?.message);
            setButtonLoading(false);
          } catch (error) {
            message.error(error.message);
            setButtonLoading(false);
          }
        });
    } catch (error) {
      setButtonLoading(false);
      message.error(
        error.response?.data?.error
          ? error?.response?.data?.error
          : "Some thing went wrong"
      );
    }
  };

  const onSync = async (account) => {
    setButtonLoading(true);
    const index = accounts.indexOf(account);
    accounts[index].loading = true;
    setAccounts(accounts);
    try {
      get(renderApiDomain() + `user/account/sync/${accounts[index].id}`)
        .then((res) => {
          message.success("Sync Success..");
          accounts[index].current_balance = res.data.data.balance;
          accounts[index].current_credit = res.data.data.credit;
          accounts[index].loading = false;
          setAccounts(accounts);
          setButtonLoading(false);
          dispatch(getAccount());
        })
        .catch((e) => {
          console.log("e", e);
          try {
            message.error(e.response.data.error);
          } catch (error) {
            message.error(error.message);
          }
          accounts[index].loading = false;
          setAccounts(accounts);
        });
    } catch (error) {
      setButtonLoading(false);
    }
  };

  function onEditLeverageShow(account) {
    setModalEditLeverage(account);
  }

  function onEditPasswordShow(account) {
    try {
      get(
        renderApiDomain() +
          `user/account/password/request-reset_code/${account.id}`
      );
      setModalEditPassword(account);
    } catch (e) {
      try {
        message.error(e.response.data.message);
      } catch (e) {
        message.error(e.message);
      }
    }
  }

  function onEditInvestorPasswordShow(account) {
    try {
      get(
        renderApiDomain() +
          `user/account/password-investor/request-reset_code/${account.id}`
      );
      setModalEditInvestorPassword(account);
    } catch (e) {
      try {
        message.error(e.response?.data.message);
      } catch (e) {
        message.error(e.message);
      }
    }
  }
  const onWithdraw = (visible, account) => {
    setAccountSelected(account);
    setModalWithdraw(visible);
  };
  const onInfoBonus = (visible) => {
    setModalInfoBonus(visible);
  };
  const validatorPassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      // const regex = new RegExp(
      //   /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/
      // );
      const regex = new RegExp(
        /^(?=.*[0-9])(?=.*[~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]{8,15}$/
      );
      if (value?.match(regex)) {
        return Promise.resolve();
      } else {
        if (value) {
          return Promise.reject(
            locale.t("dashboardWallet.modal.accountPassword_validate")
          );
        } else {
          return Promise.reject(locale.t("dashboardWallet.input_error"));
        }
      }
    },
  });

  const validatorInvestorPassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = new RegExp(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/
      );
      if (value.match(regex)) {
        return Promise.resolve();
      }
      return Promise.reject(
        locale.t("dashboardWallet.modal.accountPasswordInvestor")
      );
    },
  });
  const onSubmitWithdraw = () => {
    if (
      dataChallenge?.filter(
        (e) => e.value <= e.BonusChallengeUsers.value_progress
      )?.length !== dataChallenge?.length
    ) {
      setModalAlertConditon(true);
      setModalWithdraw(false);
    } else {
      history.push(
        `/${localStorage.getItem("locale")}/wallet/fund/withdraw?account=${
          accountSelected.account_number
        }&condition=${withdrawSelected?.id}&amount=${withdrawSelected?.amount}`
      );
    }
  };
  const showInformation = () => {
    setHoverInformation(!hoverInformation);
  };

  const isMobile = window.innerWidth < 800;
  const renderBg = (id) => {
    switch (id) {
      case 1:
        return "bg-primary-iux";
      case 2:
        return "bg-primary";
      case 3:
        return "bg-secondary";
      case 4:
        return "bg-yellow-iux";
      case 5:
        return "bg-danger";
      default:
        return "bg-primary";
    }
  };
  const rendersuffix = (sum_lots) => {
    switch (sum_lots) {
      case "sum_lots":
        return "Lots size";
      case "sum_instrument":
        return "Instrument";
      case "sum_profit":
        return "USD";
      default:
        return "";
    }
  };
  const renderLinkWelcome = () => {
    switch (props.user.profile.country_id) {
      case 10:
        return "https://www.iuxvn.com/";

      case 9:
        return "https://www.id-iux.com/";
      default:
        return "https://www.iux.com/";
    }
  };
  return (
    <RcQueueAnim duration={2000}>
      <Layout className="max-width-layout">
        <SubStyledAccountWallet>
          <div
            className="d-flex iux-borderBottomx align-items-center pl-3 pr-3"
            key={"1"}
          >
            <div
              className="d-flex"
              style={{ padding: "0px 6px 0px 6px", borderRight: "1px dashed" }}
            >
              <div>
                <div className={`accounts-logo ${renderBg(isDetail?.id)}`}>
                  {isDetail?.display?.charAt(0)}
                </div>
              </div>
              <div className="col-md">
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "700",
                    fontFamily: "sans-serif",
                  }}
                >
                  <b> {isDetail?.display}</b>
                </p>
                <p style={{ fontSize: "12px", textTransform: "uppercase" }}>
                  Account
                </p>
              </div>
            </div>
            <div className="col-md" style={{ padding: "0px 18px" }}>
              <div className="d-flex">
                <div className="minWallet-w100">
                  <p>{locale.t("dashboardWallet.account_detail")}</p>
                </div>
                <div className="color-textbox">
                  <p className="hide-responsive">
                    <Popover
                      title={isDetail?.display}
                      trigger="focus"
                      placement="bottom"
                      content={
                        <div>
                          {`${locale.t("account_detail.detail")} ${
                            isDetail?.minimum_spread
                          } PIPs`}
                          <br />
                          {`${locale.t("account_detail.detail4")} 1:${
                            isDetail?.maximum_leverage
                          }`}
                          <br />
                          {`${locale.t("account_detail.detail3")} $${
                            isDetail?.commission
                          }`}
                          <br />
                          {/* {`${locale.t("account_detail.detail2")} $${
                            isDetail?.minimum_deposit
                          }`} */}
                          {/* <br />
                          {`${locale.t("account_detail.detail5")} ${
                            isDetail?.swap
                          }`} */}
                        </div>
                      }
                    >
                      <ExclamationCircleOutlined
                        onClick={showInformation}
                        className="ml-2"
                      />
                    </Popover>
                  </p>
                  <div className="show-responsive">
                    <Popover
                      title={isDetail?.display}
                      trigger="focus"
                      placement="bottom"
                      content={
                        <div>
                          {`${locale.t("account_detail.detail")} ${
                            isDetail?.minimum_spread
                          } PIPs`}
                          <br />
                          {`${locale.t("account_detail.detail2")} 1:${
                            isDetail?.maximum_leverage
                          }`}
                          <br />
                          {`${locale.t("account_detail.detail3")} $${
                            isDetail?.commission
                          }`}
                          <br />
                          {`${locale.t("account_detail.detail4")} $${
                            isDetail?.minimum_deposit
                          }`}
                          {/* <br />
                          {`${locale.t("account_detail.detail5")} ${
                            isDetail?.swap
                          }`} */}
                        </div>
                      }
                    >
                      <ExclamationCircleOutlined
                        onClick={showInformation}
                        className="ml-2"
                      />
                    </Popover>
                  </div>
                </div>
              </div>
            </div>
            {isDetail?.active === 1 && isDetail?.type !== "welcome" && (
              <Button
                className="btn-primary-new-outline d-lg-block d-md-block d-none d-xl-block"
                onClick={() => {
                  form.resetFields();

                  setModalCreateVisible(true);
                }}
              >
                <p className="hide-create">
                  {locale.t("dashboardWallet.createAccount")}
                </p>
                <p className="hide-desktop">+</p>
              </Button>
            )}
          </div>
          {/* BTN2 */}
          {isDetail?.type !== "welcome" && (
            <div className="d-md-none mt-3 mt-md-0 text-center">
              <Button
                className={
                  isPlatform !== "MT4" ? " btn-outline-iux" : "btn-disabled-iux"
                }
                onClick={() => {
                  form.resetFields();
                  isPlatform !== "MT4" && setModalCreateVisible(true);
                }}
              >
                <p className="">
                  {isPlatform !== "MT4"
                    ? locale.t("dashboardWallet.createAccount")
                    : locale.t("dashboardWallet.only_mt5")}
                  {type}
                </p>
              </Button>
            </div>
          )}
          <div className="mt-4">
            <div>
              {/**
               * Edit leverage
               */}
              <Form form={form} ref={refLeverage}>
                <Modal
                  className="w-modal width-modal-openAccount a-modal"
                  visible={modalEditLeverage !== null}
                  title={locale.t("dashboardWallet.menu.leverage_edit")}
                  onCancel={() => {
                    setModalEditLeverage(null);
                  }}
                  footer={[
                    <div className="d-flex justify-content-between text-center">
                      <Button
                        className="btn-outline-iux"
                        key="back"
                        onClick={() => {
                          setModalEditLeverage(null);
                        }}
                      >
                        {locale.t("dashboardWallet.modal.cancel")}
                      </Button>

                      <Button
                        className="btn-primary-new-iux w-100"
                        key="submit"
                        type="primary"
                        onClick={() => {
                          onEditLeverage();
                        }}
                        loading={buttonLoading}
                      >
                        {locale.t("dashboardWallet.modal.confirm")}
                      </Button>
                    </div>,
                  ]}
                >
                  <div>
                    <Form.Item
                      name="edit_leverage"
                      rules={[
                        {
                          required: true,
                          message: locale.t("dashboardWallet.input_error"),
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        placeholder="Leverage"
                        className="user"
                      >
                        {props.user?.leverages[isDetail?.name] &&
                          props.user.leverages[isDetail?.name].map((e, i) => (
                            <Option value={e.values} key={i}>
                              <div>
                                <div className="row">
                                  <div className="col-8 pr-0">
                                    <div className="">1:{e.values} &nbsp;</div>
                                    <Divider className="m-0" />
                                  </div>
                                  {(e.values === 1 ||
                                    e.values === 100 ||
                                    e.values === 1000) && (
                                    <div
                                      className={`${
                                        e.values >= 1000
                                          ? "text-danger"
                                          : e.values < 100
                                          ? "text-primary-new-iux"
                                          : "text-yellow-new-iux"
                                      } col-4 text-right pl-0`}
                                    >
                                      <div className=""> {e.title}</div>
                                      <Divider
                                        className={`${
                                          e.values >= 1000
                                            ? "bg-danger"
                                            : e.values < 100
                                            ? "bg-primary-iux"
                                            : "bg-yellow-iux"
                                        } m-0`}
                                        style={{
                                          background:
                                            e.values >= 1000
                                              ? "#B44844"
                                              : e.values < 100
                                              ? "#1cb894"
                                              : "rgb(241, 158, 3)",
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Modal>
              </Form>

              {/**
               * Edit Password Modal
               */}
              <Form form={form} ref={refEdit} className="user-change">
                <Modal
                  className="w-modal width-modal-openAccount user-change"
                  visible={modalEditPassword !== null}
                  title={locale.t("dashboardWallet.menu.account_edit")}
                  onCancel={() => {
                    setModalEditPassword(null);
                  }}
                  footer={[
                    <div className="d-flex justify-content-between text-center">
                      <Button
                        className="btn-outline-iux "
                        key="back"
                        onClick={() => {
                          setModalEditPassword(null);
                        }}
                      >
                        {locale.t("dashboardWallet.modal.cancel")}
                      </Button>

                      <Button
                        className="btn-primary-new-iux w-100"
                        key="submit"
                        onClick={() => {
                          onEdit();
                        }}
                        loading={buttonLoading}
                      >
                        {locale.t("dashboardWallet.modal.confirm")}
                      </Button>
                    </div>,
                  ]}
                >
                  <div>
                    <Form.Item
                      name="new_password"
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                        validatorPassword,
                      ]}
                      className="icon-user"
                    >
                      <Input.Password
                        type="password"
                        placeholder={locale.t(
                          "dashboardWallet.modal.accountPassword"
                        )}
                        style={styleInput}
                        className="icon-user user-password"
                      />
                    </Form.Item>
                    <Form.Item
                      name="new_password_confirm"
                      dependencies={["new_password"]}
                      rules={[
                        {
                          required: true,
                          message: locale.t("dashboardWallet.input_error"),
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (
                              !value ||
                              getFieldValue("new_password") === value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              locale.t("menuWallet.notmathPassword")
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        type="password"
                        placeholder={locale.t(
                          "dashboardWallet.modal.accountConfirmPassword"
                        )}
                        style={styleInput}
                        className="icon-user user-password"
                      />
                    </Form.Item>
                    <Form.Item
                      name="reset_code"
                      rules={[
                        {
                          required: true,
                          message: locale.t("dashboardWallet.input_error"),
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        placeholder={`${locale.t(
                          "dashboardWallet.modal.code"
                        )} ${user.otp_to === "phone" ? "SMS" : "Email"}`}
                        style={styleInput}
                      />
                    </Form.Item>
                  </div>
                  {/* <div>
                    <Form.Item
                      name="new_password"
                      rules={[
                        {
                          required: true,
                          message: locale.t("dashboardWallet.input_error"),
                        },
                        validatorPassword,
                      ]}
                      className="icon-user"
                    >
                      <Input.Password
                        type="password"
                        placeholder={locale.t(
                          "dashboardWallet.modal.accountPassword"
                        )}
                        style={styleInput}
                        className="icon-user user-password"
                      />
                    </Form.Item>
                    <Form.Item
                      name="new_password_confirm"
                      dependencies={["new_password"]}
                      rules={[
                        {
                          required: true,
                          message: locale.t("dashboardWallet.input_error"),
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (
                              !value ||
                              getFieldValue("new_password") === value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              locale.t("menuWallet.notmathPassword")
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        type="password"
                        placeholder={locale.t(
                          "dashboardWallet.modal.accountConfirmPassword"
                        )}
                        style={styleInput}
                        className="icon-user user-password"
                      />
                    </Form.Item>
                    <Form.Item
                      name="reset_code"
                      rules={[
                        {
                          required: true,
                          message: locale.t("dashboardWallet.input_error"),
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        placeholder={`${locale.t(
                          "dashboardWallet.modal.code"
                        )} ${user.otp_to === "phone" ? "SMS" : "Email"}`}
                        style={styleInput}
                      />
                    </Form.Item>
                  </div> */}
                </Modal>
              </Form>
              {/***
               * Edit Investor Modal
               */}
              <Form form={form} ref={refEditInvestor}>
                <Modal
                  className="w-modal width-modal-openAccount user-change"
                  visible={modalEditInvestorPassword !== null}
                  title={locale.t("dashboardWallet.menu.investor_edit")}
                  onCancel={() => {
                    setModalEditInvestorPassword(null);
                  }}
                  footer={[
                    <div className="d-flex justify-content-between text-center">
                      <Button
                        className="btn-outline-iux"
                        key="back"
                        onClick={() => {
                          setModalEditInvestorPassword(null);
                        }}
                      >
                        {locale.t("dashboardWallet.modal.cancel")}
                      </Button>

                      <Button
                        className="btn-primary-new-iux w-100"
                        key="submit"
                        type="primary"
                        onClick={() => {
                          onEditInvestor();
                        }}
                        loading={buttonLoading}
                      >
                        {locale.t("dashboardWallet.modal.confirm")}
                      </Button>
                    </div>,
                  ]}
                >
                  <div>
                    <Form.Item
                      name="new_password_investor"
                      rules={[
                        {
                          required: true,
                          message: "",
                        },
                        validatorPassword,
                      ]}
                    >
                      <Input.Password
                        type="password"
                        placeholder={locale.t(
                          "dashboardWallet.modal.accountPasswordInvestor"
                        )}
                        style={styleInput}
                        className="user-password"
                      />
                    </Form.Item>
                    <Form.Item
                      name="new_password_investor_confirm"
                      dependencies={["new_password_investor"]}
                      rules={[
                        {
                          required: true,
                          message: locale.t("dashboardWallet.input_error"),
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (
                              !value ||
                              getFieldValue("new_password_investor") === value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              locale.t("menuWallet.notmathPassword")
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        type="password"
                        placeholder={locale.t(
                          "dashboardWallet.modal.accountConfirmPasswordInvestor"
                        )}
                        style={styleInput}
                        className="user-password"
                      />
                    </Form.Item>
                    <Form.Item
                      name="reset_code_investor"
                      rules={[
                        {
                          required: true,
                          message: locale.t("dashboardWallet.input_error"),
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        placeholder={`${locale.t(
                          "dashboardWallet.modal.code"
                        )} ${user.otp_to === "phone" ? "SMS" : "Email"}`}
                        style={styleInput}
                      />
                    </Form.Item>
                  </div>
                </Modal>
              </Form>

              {/* ModalWithdraw */}

              <Modal
                className="w-modal width-modal-openAccount user user-change"
                closable={true}
                visible={modalWithdraw}
                title={locale.t("withdraw_option")}
                onCancel={() => {
                  form.resetFields();
                  setModalWithdraw(false);
                }}
                footer={[
                  <div className="d-flex justify-content-center text-center">
                    <Button
                      className="btn-primary-new-iux "
                      key="submit"
                      type="primary"
                      loading={buttonLoading}
                      disabled={withdrawSelected === null}
                      onClick={() => onSubmitWithdraw()}
                    >
                      {locale.t("dashboardFund.withdraw")}
                    </Button>
                  </div>,
                ]}
              >
                <div>
                  {/* {console.log(
                    dataChallenge?.filter(
                      (e) => e.value <= e.BonusChallengeUsers.value_progress
                    )?.length === dataChallenge?.length
                  )} */}
                  <div className="text-center">{locale.t("invite_more")}</div>
                  <div className="d-flex justify-content-between">
                    <div>
                      {dataProfileBonus?.invited_number}&nbsp;
                      {locale.t("invite")}
                    </div>
                    <div>
                      {dataProfileBonus?.available_balance > 0
                        ? parseFloat(
                            dataProfileBonus?.available_balance
                          )?.toFixed(2)
                        : 0}
                      &nbsp; USD
                    </div>
                  </div>
                  {dataCondition?.map((e, i) => (
                    <div
                      className={`box-bonus
                      ${
                        dataProfileBonus?.invited_number >= e.invited_number &&
                        dataProfileBonus?.available_balance >= e.withdrawable
                          ? ""
                          : "-disabled"
                      }
                       ${withdrawSelected?.id === e.id && "active"} 
                       
                        d-flex align-items-center justify-content-between mt-3 cursor-pointer`}
                      key={i + "condition"}
                      onClick={() => {
                        if (
                          dataProfileBonus?.invited_number >=
                            e.invited_number &&
                          dataProfileBonus?.available_balance >= e.withdrawable
                        ) {
                          setWithdrawSelected({
                            id: e.id,
                            amount: e.withdrawable,
                          });
                        }
                        // setWithdrawSelected({
                        //   id: e.id,
                        //   amount: e.withdrawable,
                        // });
                        // setWithdrawSelected({
                        //   id: e.id,
                        //   amount: e.withdrawable,
                        // });
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          src={`/assets/images/icons/dashboard/tick-bonus${
                            withdrawSelected?.id === e.id ? "-active" : ""
                          }.svg`}
                          alt="tick-bonus"
                        />
                        <div
                          className="fs-24 font-weight-bold"
                          style={{ marginLeft: 20 }}
                        >
                          {e.invited_number}
                        </div>
                        <div
                          style={{
                            lineHeight: 1,
                            marginLeft: 10,
                            whiteSpace: "pre-line",
                          }}
                        >
                          {locale.t("invite_person")}
                        </div>
                      </div>
                      <img
                        src="/assets/images/icons/dashboard/right-arrow.svg"
                        alt="arrow-right"
                      />
                      <div className="d-flex align-items-center">
                        <div className="fs-24 font-weight-bold">
                          ${e.withdrawable}
                        </div>
                        <div
                          style={{
                            lineHeight: 1,
                            marginLeft: 10,
                            whiteSpace: "pre-line",
                          }}
                        >
                          {locale.t("maximum_withdraw")}
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="">
                    <div
                      className="mt-4 d-flex "
                      style={{
                        border: "1px solid #EEEEEE",
                        borderRadius: "5px",
                      }}
                    >
                      <div
                        className="p-3 text-white cursor-pointer"
                        style={{
                          background: "#999999",
                          borderRadius: "5px",
                          whiteSpace: "nowrap",
                        }}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${renderLinkWelcome()}en/register?invite=${
                              props?.user?.bonus_account?.code
                            }`
                          );
                          message.success("Copied to clipboard Success..");
                        }}
                      >
                        {locale.t("invie_link")}
                      </div>
                      <div
                        className="p-3 "
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "calc(100% - 165px)",
                        }}
                      >
                        {`${renderLinkWelcome()}${localStorage.getItem(
                          "locale"
                        )}/register?invite=${props?.user?.bonus_account?.code}`}
                      </div>

                      <div
                        className="py-3 col  cursor-pointer"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${renderLinkWelcome()}${localStorage.getItem(
                              "locale"
                            )}/register?invite=${
                              props?.user?.bonus_account?.code
                            }`
                          );
                          message.success("Copied to clipboard Success..");
                        }}
                      >
                        <img
                          src="/assets/images/icons/dashboard/copy.svg"
                          width={16}
                          alt="copy"
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="p-3">
                    <div
                      className="mt-4 row "
                      style={{
                        border: "1px solid #EEEEEE",
                        borderRadius: "5px",
                      }}
                    >
                      <div
                        className="p-3 text-white col-4"
                        style={{ background: "#999999", borderRadius: "5px" }}
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `https://www.iuxmarkets.com/en/register?invite=${props?.user?.bonus_account?.code}`
                          );
                          message.success("Copied to clipboard Success..");
                        }}
                      >
                        Invitation link
                      </div>
                      <div
                        className="py-3 col-7"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <div>
                          {` https://www.iuxmarkets.com/en/register?invite=${props?.user?.bonus_account?.code}`}
                        </div>
                      </div>

                      <div className="py-3 col-1">
                        <img
                          src="/assets/images/icons/dashboard/copy.svg"
                          width={16}
                          alt="copy"
                        />
                      </div>
                    </div>
                  </div> */}
                  <div className="mt-4">
                    {locale.t("reminder")}:
                    <ul className="mt-3">
                      <li style={{ color: "#999999" }}>
                        {locale.t("remider_detail")}
                      </li>
                    </ul>
                  </div>
                </div>
              </Modal>
              {/* ModalInfoBonus */}
              <Modal
                className="w-modal width-modal-openAccount user user-change"
                closable={false}
                visible={modalInfoBonus}
                title={
                  <div className="d-flex justify-content-center">
                    {locale.t("withdraw_conditions")}
                    <span
                      onClick={() => {
                        if (countDown !== 0) {
                          message.error(locale.t("bonus_update_warning"), 2.5);
                        } else {
                          patchChallenge();
                        }
                      }}
                      className={`bage-update-bonus ml-2 d-flex align-items-center justify-content-center cursor-pointer`}
                    >
                      <svg
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M2.49967 1.33325C2.86786 1.33325 3.16634 1.63173 3.16634 1.99992V3.83326L4.05506 3.03094C5.23408 1.9757 6.79265 1.33325 8.49967 1.33325C12.1816 1.33325 15.1663 4.31802 15.1663 7.99992C15.1663 11.6818 12.1816 14.6666 8.49967 14.6666C5.32611 14.6666 2.67191 12.4497 1.99821 9.48078C1.91674 9.12171 2.14177 8.76459 2.50083 8.68311C2.85989 8.60164 3.21702 8.82667 3.29849 9.18573C3.83741 11.5607 5.96234 13.3333 8.49967 13.3333C11.4452 13.3333 13.833 10.9454 13.833 7.99992C13.833 5.0544 11.4452 2.66659 8.49967 2.66659C7.13379 2.66659 5.88921 3.17917 4.94532 4.02353L4.23301 4.66659H5.83301C6.2012 4.66659 6.49967 4.96506 6.49967 5.33325C6.49967 5.70144 6.2012 5.99992 5.83301 5.99992H2.49967C2.13148 5.99992 1.83301 5.70144 1.83301 5.33325V1.99992C1.83301 1.63173 2.13148 1.33325 2.49967 1.33325Z"
                          fill="#1CB894"
                        />
                      </svg>
                      {locale.t("bonus_update")}
                    </span>
                  </div>
                }
                onCancel={() => {
                  setModalInfoBonus(false);
                }}
                footer={[
                  <div className="d-flex justify-content-center text-center">
                    <Button
                      className="btn-primary-new-iux "
                      key="submit"
                      onClick={() => setModalInfoBonus(false)}
                      // loading={buttonLoading}
                      // onClick={onCreate}
                    >
                      {locale.t("close")}
                    </Button>
                  </div>,
                ]}
              >
                <div>
                  <div className="text-center">{locale.t("complete_all")}</div>
                  {dataChallenge?.map((e, i) => (
                    <div
                      className={`box-bonus-disabled ${
                        withdrawSelected === e.id && "active"
                      }  mt-3 `}
                      key={i + "condition"}
                      // onClick={() => setWithdrawSelected(e.id)}
                    >
                      {e.BonusChallengeUsers.value_progress === null ? (
                        <div className="bage-condition-notyet">
                          {locale.t("not_yet")}
                        </div>
                      ) : e.value <= e.BonusChallengeUsers.value_progress ? (
                        <div className="bage-condition-complete">
                          {" "}
                          {locale.t("complete")}
                        </div>
                      ) : (
                        <div className="bage-condition-processing">
                          {locale.t("processing")}
                        </div>
                      )}

                      <div className="d-flex justify-content-between mt-2">
                        <div className="w-100 fs-13 font-weight-bold">
                          {e.name}
                        </div>
                        <div className=" text-right " style={{ width: "50%" }}>
                          {e.BonusChallengeUsers.value_progress > e.value
                            ? e.value
                            : e.BonusChallengeUsers.value_progress > 0
                            ? e.BonusChallengeUsers.value_progress.toFixed(2)
                            : 0}
                          &nbsp;/&nbsp;
                          {e.value} {rendersuffix(e.keyname)}
                        </div>
                      </div>
                      <div>
                        <Progress
                          strokeColor={{
                            "0%": "#169376",
                            "100%": "#169376",
                          }}
                          percent={
                            (e.BonusChallengeUsers.value_progress * 100) /
                            e.value
                          }
                          showInfo={false}
                        />
                      </div>
                    </div>
                  ))}
                  <div className="mt-4">
                    {locale.t("reminder")}:
                    <ul className="mt-3">
                      <li style={{ color: "#999999" }}>
                        {locale.t("remider_condition_1")}
                      </li>
                      <li style={{ color: "#999999" }} className="mt-2">
                        {locale.t("remider_condition_2")}
                      </li>
                      <li style={{ color: "#999999" }} className="mt-2">
                        {locale.t("remider_condition_3")}
                      </li>
                    </ul>
                  </div>
                </div>
              </Modal>
              {/* Alert Conditions */}
              <Modal
                className="w-modal width-modal-openAccount user user-change"
                closable={true}
                visible={modalAlertConditon}
                title={"Withdrawal Conditions"}
                onCancel={() => {
                  setModalAlertConditon(false);
                }}
                footer={[
                  <div className="d-flex justify-content-center text-center">
                    <Button
                      className="btn-primary-new-iux "
                      key="submit"
                      onClick={() => {
                        setModalAlertConditon(false);
                        setModalInfoBonus(true);
                      }}
                      // loading={buttonLoading}
                      // onClick={onCreate}
                    >
                      {locale.t("conditions")}
                    </Button>{" "}
                    <Button
                      className="btn-outline-iux "
                      key="submit"
                      onClick={() => setModalAlertConditon(false)}
                      // loading={buttonLoading}
                      // onClick={onCreate}
                    >
                      {locale.t("close")}
                    </Button>
                  </div>,
                ]}
              >
                <div className="text-center">
                  You have not pass all the conditions for withdrawing the bonus
                </div>
              </Modal>
            </div>
          </div>

          {props.user.userVerify.status === "accepted" ? (
            accounts.filter(
              (account) =>
                account.type === "welcome" && account.platform === isPlatform
            ).length > 0 ? (
              <div
                key={"3"}
                className="row "
                style={{
                  margin: "12px",
                  display: isMobile ? (mobile ? "none" : "") : "",
                }}
              >
                {accounts
                  .filter(
                    (account) =>
                      account.type === "welcome" &&
                      account.platform === isPlatform
                  )
                  .map((account, index) => (
                    <div key={index} className="col-md minandmax-width">
                      <RealAccountCardx
                        onWithdraw={onWithdraw}
                        onInfoBonus={onInfoBonus}
                        isWelcome={true}
                        account={account}
                        onEditLeverageShow={onEditLeverageShow}
                        onEditPasswordShow={onEditPasswordShow}
                        onEditInvestorPasswordShow={onEditInvestorPasswordShow}
                        key={isDetail?.display + "_" + index}
                        onSync={onSync.bind(this, account)}
                      />
                    </div>
                  ))}
              </div>
            ) : (
              <div className="d-flex justify-content-center align-items-center flex-column pt-5 pb-5">
                <img
                  src="/assets/images/icons/none-account.svg"
                  alt="tranfer-icons"
                />
                <p className="mb-4 mt-2">
                  {locale.t("dashboardWallet.notAccount")}
                </p>
                {isDetail?.active === 1 && (
                  <Button
                    onClick={() => {
                      form.resetFields();
                      setModalCreateVisible(true);
                    }}
                    className="mt-4 btn-primary-new-iux"
                    htmlType="submit"
                    type="primary"
                  >
                    <p className="hide-create">
                      + {locale.t("dashboardWallet.createAccount")}
                    </p>
                  </Button>
                )}
              </div>
            )
          ) : (
            <VerifyUser />
          )}
        </SubStyledAccountWallet>
      </Layout>
    </RcQueueAnim>
  );
};

const mapStateToProps = ({ user, i18n }) => {
  return { user, locale: i18n.locale };
};

export default connect(mapStateToProps, {
  addAccount,
  getArchive,
  updateLaverage,
  archiveAccount,
  deleteAccount,
})(Accounts);
