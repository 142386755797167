import PropTypes from "prop-types";
import React, { useState, useEffect, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import {
  Row,
  Divider,
  Input,
  Form,
  Button,
  Col,
  message,
  Tooltip,
  Modal,
  Statistic,
} from "antd";
import { patch, post } from "../../../../helper/request";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import OtpInput from "../../../../components/otpinput/OtpInput";
import { CheckCerrency } from "../../../../components/checkcurrency";
import { fCurrencySymbol } from "../../../../helper/formatNumber";
import PaymentSlip from "../../../../components/paymentslip/PaymentSlip";
import { useHistory } from "react-router-dom";
import moment from "moment";
import CryptoRecive from "./CryptoRecive";
import CryptoConfirm from "./CryptoConfirm";
import { fncToFixed } from "../../../../helper/toFixedCustom";
import { renderApiDomain } from "../../../../helper/renderApiDomain";
const locale = require("react-redux-i18n").I18n;
const { Countdown } = Statistic;
export const WithDrawThirdStep = (props) => {
  const {
    account_withdraw_id,
    paymentKey,
    paymentSelected,
    paymentId,
    rate,
    walletAddress,
    cryptoFee,
    cryptoRate,
    cryptoSelected,
    amount,
  } = props;

  const profileCountry = useSelector((state) => state.user.profileCountry);
  const user = useSelector((state) => state.user);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    history.push(`/${localStorage.getItem("locale")}/wallet/`);
    setIsModalOpen(false);
  };
  const [loading, setLoading] = useState(false);
  const [laos, setLaos] = useState(null);
  const [isError, setIsError] = useState(false);
  // const [rate, setRate] = useState(0);
  const [suffix, setSuffix] = useState("");
  const [prefix, setPrefix] = useState("");
  const [otp, setOtp] = useState("");
  const onChange = (value) => setOtp(value);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTime, setIsTime] = useState(moment().add(180, "s").toDate());
  // const [isTime, setIsTime] = useState(moment().add(5, "s").toDate());
  const history = useHistory();
  const overviewMemo = useMemo(
    () => (
      <Countdown
        format="ss"
        value={isTime}
        onFinish={() => {
          setIsTime(null);
        }}
        className="fs-12 withdraw-otp"
      />
    ),
    [isTime]
  );

  const onFinish = (values) => {
    setButtonLoading(true);

    post(renderApiDomain() + "user/account/withdraw", {
      payment_id: paymentSelected.id,
      account_withdraw_id: account_withdraw_id.account_withdraw_id,
      verify_code: otp,
    })
      .then((res) => {
        setButtonLoading(false);
        showModal();
        // props.onSuccess({
        //   message: {
        //     title: locale.t("withdraw_info.success.title"),
        //     description: locale.t("description.success.description"),
        //   },
        // });
      })
      .catch((e) => {
        setIsError(true);
        setButtonLoading(false);
        message.error(e?.response?.data?.error);
      });
  };

  function onRequestOTP() {
    try {
      setIsLoading(true);

      patch(renderApiDomain() + "user/account/initial-withdraw/otp/resend", {
        account_withdraw_id: account_withdraw_id.account_withdraw_id,
      })
        .then((res) => {
          setIsLoading(false);
          setIsTime(moment().add(180, "s").toDate());
        })
        .catch((e) => {
          // props.onBackStep();
          setIsTime(null);
          console.log(e);
          // message.error(locale.t("dashboardWallet.error.title"));
          if (e?.response?.data?.code === 3012) {
            message.error(
              e?.response?.data?.error + ". Please choose a new payment."
            );
          } else if (e?.response?.data?.code === 3003) {
            message.error(
              e?.response?.data?.error +
                CheckCerrency(props?.user?.profile?.country_id)?.iso
            );
          } else {
            message.error(e?.response?.data?.error);
          }

          setIsLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  }
  const WithdrawFormCrypto = () => {
    return (
      <div style={{ padding: "0 40px" }} className="user">
        <Row>
          <h4 className="mt-2 mb-2">{locale.t("withdraw_info.result")}</h4>
        </Row>
        <Row>
          <Col span={12}>{locale.t("withdraw_info.account.name")}:</Col>
          <Col span={12}>{props.bookBankVerify.bank_account_name}</Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>{locale.t("withdraw_info.account.number")}:</Col>
          <Col span={12}>
            {props.paymentKey === "alphapo"
              ? props.address
              : props.bookBankVerify.bank_account_number}
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>{locale.t("withdraw_info.account.bank")}:</Col>
          <Col span={12}>{props.bookBankVerify.bank_name}</Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>{locale.t("withdraw_info.account.amount")}:</Col>
          <Col span={12}>
            <span>
              {props.amount}{" "}
              {props.account.account_type === "Cent" ? "USC" : "USD"}
            </span>
          </Col>
        </Row>
        <Divider />
        <p>{`${locale.t("withdraw_info.account.Code")} ${
          props.otp_to === "phone" ? "SMS" : "Email"
        }`}</p>
        <Form onFinish={onFinish}>
          <Form.Item name=" " rules={[{ required: true }]} className="user">
            {/* <Input
              style={{ width: "20%", margin: "16px 0" }}
              className="w-sm-100 "
            /> */}
            <OtpInput value={otp} valueLength={6} onChange={onChange} />
          </Form.Item>
          <Divider />
          <div style={{ display: "inline-block", width: "100%" }}>
            <Form.Item style={{ float: "center" }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={buttonLoading}
                className="btn-primary-new-iux"
              >
                {locale.t("deposit_info.confirm")}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    );
  };
  const renderCase = () => {
    switch (props.paymentKey) {
      case "thai_bank":
        return <WithdrawFormCrypto />;
      case "alphapo":
        return <WithdrawFormCrypto />;
      case "laos_bank":
        return <WithdrawFormCrypto />;
      default:
        return <WithdrawFormCrypto />;
    }
  };
  useEffect(() => {
    setLoading(true);
    setPrefix(profileCountry?.symbol);
    if (props?.user?.profile?.country_id === 10) {
      setSuffix("USD");
    }
    if (props?.user?.profile?.country_id === 6) {
      setSuffix("USD");
    }
    if (props?.user?.profile?.country_id === 1) {
      setSuffix("USD");
    }
    if (props?.user?.profile?.country_id === 8) {
      setSuffix("USD");
    }
    if (props?.user?.profile?.country_id === 9) {
      setSuffix("USD");
    }
    setLoading(false);
  }, [props.user.profile]);

  const renderRateWithdraw = () => {
    if (paymentKey === "alphapo") {
      if (isNaN((props.amount / props.rate).toFixed(6))) return 0.0;
      else return (props.amount / props.rate).toFixed(6);
    } else if (props.account.account_type === "Cent") {
      return ` ${((props.amount / 100) * parseFloat(props.rate)).toFixed(2)}`;
    } else {
      return ` ${(props.amount * parseFloat(props.rate)).toFixed(2)}`;
    }
  };
  const renderRateTotal = () => {
    if (props.account?.account_type === "Cent") {
      return ` ${(amount / 100 - parseFloat(cryptoFee) / parseFloat(cryptoRate))
        .toFixed(cryptoSelected?.fixed)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    } else {
      return ` ${(amount - parseFloat(cryptoFee) / parseFloat(cryptoRate))
        .toFixed(cryptoSelected?.fixed)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    }
  };
  // const renderRateWithdrawTotal = () => {
  //   if (props.account?.account_type === "Cent") {
  //     return ` ${(
  //       (amount / 100) * parseFloat(cryptoRate) -
  //       parseFloat(cryptoFee)
  //     )
  //       .toFixed(cryptoSelected?.fixed)
  //       .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
  //   } else {
  //     return ` ${(amount * parseFloat(cryptoRate) - parseFloat(cryptoFee))
  //       .toFixed(cryptoSelected?.fixed)
  //       .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
  //   }
  // };

  const renderRateWithdrawTotal = () => {
    if (props.account?.account_type === "Cent") {
      let total =
        (amount / 100) * parseFloat(cryptoRate) - parseFloat(cryptoFee);

      return ` ${fncToFixed(total, cryptoSelected?.fixed)} `;
    } else {
      let total = amount * parseFloat(cryptoRate) - parseFloat(cryptoFee);

      return ` ${fncToFixed(total, cryptoSelected?.fixed)} `;
    }
  };
  return (
    <div className="user">
      <div className="user">
        <span className="fs-20 font-weight-bold ">
          3.{locale.t("withdraw_step.third_step.title")}
        </span>{" "}
        <div className="w-12 text-lightgray-iux my-3">
          {locale.t("deposit_step.second_step.you_receive")}
        </div>
        <Form onFinish={onFinish}>
          <div className="container-withdraw-otp ">
            {paymentKey === "b2binpay" && (
              <CryptoConfirm
                amount={props?.amount}
                account={props?.account}
                walletAddress={walletAddress}
                cryptoFee={cryptoFee}
                cryptoRate={cryptoRate}
                currencySelected={cryptoSelected}
              />
            )}
            {paymentKey !== "gcash_payout" &&
              paymentKey !== "maya_payout" &&
              paymentKey !== "b2binpay" && (
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={14}>
                    <div className="box-payment-detail ">
                      <span className="w-12 mb-3 ">
                        {" "}
                        {locale.t("withdraw_info.bank_detail")}
                      </span>
                      <div className="fw-300">
                        <div className="mt-3 mb-3 text-lightgray-iux d-flex align-items-center justify-content-between">
                          <div>{locale.t("withdraw_info.account.name")}</div>
                          <div>{props.bookBankVerify?.bank_account_name}</div>
                        </div>
                        <div className="mb-3 text-lightgray-iux d-flex align-items-center justify-content-between">
                          <div>{locale.t("withdraw_info.account.number")}</div>
                          <div>{props.bookBankVerify?.bank_account_number}</div>
                        </div>
                        <div className="mb-3 text-lightgray-iux d-flex align-items-center justify-content-between">
                          <div>{locale.t("withdraw_info.account.bank")}</div>
                          <div>{props.bookBankVerify?.bank_name}</div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} md={1}>
                    <Divider
                      type="vertical"
                      style={{ height: "100%" }}
                      className="d-none d-sm-block border-divider"
                    />
                    <Divider
                      style={{ margin: 0 }}
                      className="d-flex d-sm-none border-divider"
                    />
                  </Col>
                  <Col xs={24} md={8}>
                    <div className="box-payment-fund-detail ">
                      <span className="w-12  mb-3">
                        {" "}
                        {locale.t("withdraw_info.fund_detail")}
                      </span>
                      <div className="fw-300">
                        <div className="mt-3 mb-3 text-lightgray-iux d-flex align-items-center justify-content-between">
                          <div>{locale.t("withdraw_info.local_amount")}</div>
                          <div>
                            {fCurrencySymbol(renderRateWithdraw())}&nbsp;
                            {profileCountry?.symbol}
                          </div>
                        </div>
                        <div className="mb-3 text-lightgray-iux d-flex align-items-center justify-content-between">
                          <div>
                            {locale.t("deposit_info.currency_rate")}&nbsp;{" "}
                            <Tooltip
                              placement="topLeft"
                              title={
                                <div className="fs-10">
                                  {locale.t("faq.notice")}
                                </div>
                              }
                            >
                              <InfoCircleOutlined className="text-lightgray fs-16" />
                            </Tooltip>
                          </div>
                          <span>
                            {fCurrencySymbol(rate.toFixed(2))}&nbsp;
                            {profileCountry?.symbol}
                          </span>
                        </div>
                        <div className="mb-3 text-lightgray-iux d-flex justify-content-between">
                          {locale.t("deposit_step.second_step.fee")}
                          <span>0.00 USD</span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            {(paymentKey === "gcash_payout" ||
              paymentKey === "maya_payout") && (
              <Row gutter={[16, 16]}>
                <Col xs={24} md={24}>
                  <div className=" px-4 py-3">
                    <span className="w-12 mb-3 ">
                      {locale.t("dashboardWallet.detail")}
                    </span>
                    <div className="fw-300">
                      <div className="mt-3 mb-3 text-lightgray-iux d-flex align-items-center justify-content-between">
                        <div>{paymentSelected?.name} Account</div>
                        <div>{user?.profile?.phone_number}</div>
                      </div>
                      <div className="mb-3 text-lightgray-iux d-flex align-items-center justify-content-between">
                        <div>
                          {locale.t("deposit_step.second_step.description")}
                        </div>
                        <div>
                          {fCurrencySymbol(renderRateWithdraw())}&nbsp;
                          {profileCountry?.symbol}
                        </div>
                      </div>
                      <div className="mb-3 text-lightgray-iux d-flex align-items-center justify-content-between">
                        <div>
                          {locale.t("withdraw_info.rate")}
                          &nbsp;{" "}
                          <Tooltip
                            placement="topLeft"
                            title={
                              <div className="fs-10">
                                {locale.t("faq.notice")}
                              </div>
                            }
                          >
                            <InfoCircleOutlined className="text-lightgray fs-16" />
                          </Tooltip>
                        </div>
                        <span>
                          {fCurrencySymbol(rate.toFixed(2))}&nbsp; USD/
                          {profileCountry?.symbol}
                        </span>
                      </div>
                      <div className="mb-3 text-lightgray-iux d-flex justify-content-between">
                        {locale.t(" withdraw_info.fee")}
                        <span>0.00 USD</span>
                      </div>
                      <div
                        className="text-center py-2"
                        style={{ background: "#F8D04626", borderRadius: 4 }}
                      >
                        {locale.t("withdraw_info.warning_wallet")}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            )}

            <Divider style={{ margin: 0 }} className="border-divider" />
            <div className="d-flex flex-column align-items-center p-3">
              <p className="text-lightgray-iux fw-300">{`${locale.t(
                "withdraw_info.account.Code"
              )} ${props.otp_to === "phone" ? "SMS" : "Email"}`}</p>

              <div className="mt-3">
                <OtpInput value={otp} valueLength={6} onChange={onChange} />
              </div>
              <div className="fs-12 mt-3 text-center text-lightgray-iux d-flex align-items-center justify-content-center flex-column">
                {isError && (
                  <div className="fs-12 text-is-error">
                    {locale.t("codenotmatch")}&nbsp;
                    {/* <span
                      className="text-primary-new-iux font-weight-bold cursor-pointer"
                      onClick={() => {
                        if (!isLoading) {
                          onRequestOTP();
                        }
                      }}
                    >
                      {locale.t("requestotp")}
                    </span> */}
                  </div>
                )}
                <div
                  className={` align-items-center ${
                    isTime ? "d-flex" : "d-none"
                  }`}
                >
                  {locale.t("codeexpiresin")}&nbsp;{overviewMemo} &nbsp;
                  {locale.t("seconds")}
                </div>
                {isTime === null && (
                  <div className="fs-12">
                    {locale.t("didotp")}&nbsp;
                    <span
                      className="text-primary-new-iux font-weight-bold cursor-pointer"
                      onClick={() => {
                        if (!isLoading) {
                          onRequestOTP();
                        }
                      }}
                    >
                      {locale.t("requestotp")}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <Divider style={{ margin: 0 }} className="border-divider" />
            <div className="d-flex justify-content-between box-withdraw-otp-amount">
              {/* <div>{locale.t("deposit_step.second_step.description")}</div> */}
              <div>{locale.t("withdraw_info.total_amount")}</div>
              {paymentKey === "b2binpay" && (
                <div className="text-right">
                  <div className="font-weight-bold fs-16">
                    {renderRateWithdrawTotal()}&nbsp;
                    {cryptoSelected?.currency}
                  </div>
                  <div className="fs-14">
                    ≈{" "}
                    {(renderRateWithdrawTotal() / cryptoRate).toFixed(
                      cryptoSelected?.fixed
                    )}{" "}
                    USD
                  </div>
                </div>
              )}
              {paymentKey !== "b2binpay" && (
                <div>
                  {fCurrencySymbol(props.amount)}
                  &nbsp;
                  {props?.account?.account_type === "Cent" ? "USC" : "USD"}
                </div>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-between mt-3">
            <Button
              className="btn-outline-iux  mr-1 fw-bold button-default-w "
              onClick={props.onBackStep}
            >
              {locale.t("back")}
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              loading={buttonLoading}
              disabled={buttonLoading || otp.trim()?.length !== 6}
              className="btn-primary-new-iux button-default-w "
            >
              {locale.t("depositPage.accept.confirm")}
              {/* deposit_info
              Confirm */}
              {/* {locale.t("register_button_text")} */}
            </Button>
          </div>
        </Form>
      </div>
      <Modal
        title={
          <div className="text-center fs-20">
            {locale.t("withdraw_info.request_success")}
          </div>
        }
        open={isModalOpen}
        // open={true}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <PaymentSlip
          paymentSelected={paymentSelected}
          onClose={handleCancel}
          rate={rate}
          type={"withdraw"}
          amount={props?.amount}
          account={props?.account}
          localamount={fCurrencySymbol(renderRateWithdraw())}
          bookbank={props?.bookBankVerify}
          isPayment={true}
          wallet_address={walletAddress}
          cryptoFee={cryptoFee}
          cryptoRate={cryptoRate}
          currencySelected={cryptoSelected}
        />
      </Modal>
    </div>
  );
};

WithDrawThirdStep.propTypes = {};

const mapStateToProps = ({ user, i18n }) => {
  const { bookBankVerify, otp_to, profile } = user;
  return { bookBankVerify, locale: i18n.locale, otp_to, user };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WithDrawThirdStep);
